import React, {useState, useEffect} from "react"
import { Container, Row, Col, Button, Tabs, Tab, Table, Form } from "react-bootstrap"
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import CustomPagination from './CustomPagination';
import Swal from "sweetalert2";

import { Placeholder } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";

const Admins = () => {
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [users, setUsers] = useState([]);
    const {REACT_APP_API_URL} = process.env
    axios.defaults.baseURL = REACT_APP_API_URL;
    const history = useNavigate();

    const [inputVals, setInputVals] = useState({
        pusername: "",
        ppassword: "",
        confPassword: "",
        pphone: "",
        pemail: ""
       });
    const [msg, setMsg] = useState('');   

    const [searchFilter, setSearchFilter] = useState(''); // filter the search
    const [currentPage, setCurrentPage] = useState(1); // set the current page
    const pageSize = 20; // show row in table

    useEffect(() => {
        refreshToken();
        getUsers();
      }, [searchFilter]);

      const create_admin = async (e) => {
        e.preventDefault();
        try {
            console.log('INPUT VALUE: ', inputVals)
            await axios.post('admins', {
                pusername: inputVals.pusername,
                ppassword: inputVals.ppassword,
                confPassword: inputVals.confPassword,
                pphone: inputVals.pphone,
                pemail: inputVals.pemail,
            },  {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            Swal.fire({
              title: "Creation successful",
              text:"New Admin now can login into dashboard",
              icon: "success",
              color: "#fff",
              background: "#2b3035",
              timer: 3000,
              position: 'top-end'
            });
            getUsers();
        } catch (error) {
            if (error.response) {
                setMsg(error.response.data.msg);
            }
        }
    }

      const handleFilter = (e) => {
        setSearchFilter(e.target.value);
      };
    
      const filteredData = users.filter(
        (item) =>
            item.pusername.toLowerCase().includes(searchFilter.toLowerCase()) 
      );
    
      const paginatedData = filteredData.slice(
        (currentPage - 1) * pageSize,
        currentPage * pageSize
      );

      const refreshToken = async () => {
        try {
            const response = await axios.get('token');
            setToken(response.data.accessToken);
            const decoded = jwtDecode(response.data.accessToken);
            // console.log('DECODED: ', decoded);
            setExpire(decoded.exp);
        } catch (error) {
            if (error.response) {
                history("/");
            }
        }
    }
 
    const axiosJWT = axios.create();
 
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get('token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwtDecode(response.data.accessToken);
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });
 
    const getUsers = async () => {
        const response = await axiosJWT.get('admins', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        setUsers([...response.data]);
        // console.log('USERS: ', response.data);
    }

    const deleteUser = (data) => (e) => {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this action!",
          icon: "warning",
          color: "#fff",
          background: "#2b3035",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Delete!"
        }).then((result) => {
          if (result.isConfirmed) {
            axios.delete('admins/'+data, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(res=>{
                getUsers();
            });
            Swal.fire({
              title: "Deletion Successful",
              text: "Admin has been removed.",
              icon: "success",
              color: "#fff",
              background: "#2b3035",
            });
          }
        })
        
      }

      const placeholder = [
        <tr>
          <td><Placeholder animation="glow">
                <Placeholder xs={12} />
              </Placeholder></td>
          <td><Placeholder animation="glow">
                <Placeholder xs={12} />
          </Placeholder></td>
          <td><Placeholder animation="glow">
                <Placeholder xs={12} />
              </Placeholder></td>
          <td><Placeholder animation="glow">
                <Placeholder xs={12} />
              </Placeholder></td>
          <td><Placeholder animation="glow">
                <Placeholder xs={12} />
              </Placeholder></td>
          <td><Placeholder animation="glow">
                <Placeholder xs={12} />
              </Placeholder></td>
          <td><Placeholder animation="glow">
                <Placeholder xs={12} />
              </Placeholder></td>
        </tr>
      ]

      const listPlaceholder = [];

    for (let i = 0; i < 10; i++)
    {
      listPlaceholder.push(placeholder);
    }

  return (
    <>
      <Container>
        <Tabs
                defaultActiveKey="admins"
                id="justify-tab-example"
                className="mb-3 text-white"
                justify
                >
                <Tab eventKey="admins" title="Admins">
                    <Row>
                      <Col>
                      <input
                        style={{ width: "200px" }}
                        className='form-control mb-2'
                        placeholder='Search'
                        value={searchFilter}
                        onChange={handleFilter}
                      />
                      <Table striped bordered hover variant="dark" responsive>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Username</th>
                            <th>Password</th>
                            <th>Phone</th>
                            <th>Email</th>
                            <th>Refresh Token</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                        {paginatedData.length > 0 ? (
                            paginatedData.map((user, i) => (
                              <tr key={i} style={{ background: '#fff' }}>
                                <td>{(currentPage - 1) * pageSize + i + 1}</td>
                                <td>{user.pusername}</td>
                                <td>{user.ppassword}</td>
                                <td>{user.pphone}</td>
                                <td>{user.pemail}</td>
                                <td>{user.refresh_token}</td>
                                <td><Button variant="outline-danger" onClick={deleteUser(user.pID)}>Delete</Button></td>
                              </tr>
                            ))
                          ) : (
                            <>
                            {listPlaceholder}
                            <tr>
                               <td colSpan="7" className="text-center">
                                <Spinner animation="border" role="status" size="sm"/>
                                <Spinner animation="border" role="status" size="sm"/>  
                                <Spinner animation="border" role="status" size="sm" className="me-2"/>Loading Data...
                                </td>
                            </tr>
                            </>
                          )}
                        </tbody>
                      </Table>
                      </Col>
                    </Row>
                    <Row className="text-center my-3">
                      <Col>
                      <div className='d-flex justify-content-md-center'>
                      {filteredData.length > 0 &&
                        <>
                          <CustomPagination
                            itemsCount={filteredData.length}
                            itemsPerPage={pageSize}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            alwaysShown={true}
                          />
                        </>
                      }
                      </div>
                      </Col>
                      </Row>
                </Tab>
                <Tab eventKey="create" title="Add Admins">
                    <Row>
                        <Form onSubmit={create_admin} className="box" encType="multipart/form-data">
                            <p className="has-text-centered">{msg}</p>
                            <Form.Group className="mb-3" controlId="Username.ControlInput1">
                                <Form.Label className="text-white">Username</Form.Label>
                                <Form.Control type="text" placeholder="input username..." 
                                value={inputVals.pusername} onChange={(e) => setInputVals({...inputVals, pusername:e.target.value})}/>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="Password.ControlInput1">
                                <Form.Label className="text-white">Password</Form.Label>
                                <Form.Control type="password" placeholder="input password..." 
                                value={inputVals.ppassword} onChange={(e) => setInputVals({...inputVals, ppassword:e.target.value})}/>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="confPassword.ControlInput1">
                                <Form.Label className="text-white">Confirm Password</Form.Label>
                                <Form.Control type="password" placeholder="input password..." 
                                value={inputVals.confPassword} onChange={(e) => setInputVals({...inputVals, confPassword:e.target.value})}/>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="Phone.ControlInput1">
                                <Form.Label className="text-white">Phone Number</Form.Label>
                                <Form.Control type="text" placeholder="input phone number..." 
                                value={inputVals.pphone} onChange={(e) => setInputVals({...inputVals, pphone:e.target.value})}/>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="Email.ControlInput1">
                                <Form.Label className="text-white">Email</Form.Label>
                                <Form.Control type="email" placeholder="input email..." 
                                value={inputVals.pemail} onChange={(e) => setInputVals({...inputVals, pemail:e.target.value})}/>
                            </Form.Group>
                           
                            
                            <Button variant="outline-primary" type="submit">Create</Button>
                            
                        </Form>
                     </Row>
                </Tab>
        </Tabs>
      </Container>
    </>
  )
}

export default Admins