import React, {useState, useEffect} from "react"
import { Container, Row, Col, Button, Figure, Tabs, Tab, Table, Form, ButtonGroup } from "react-bootstrap"
import axios from "axios";
import { useLocation, useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

import InputGroup from 'react-bootstrap/InputGroup';
import * as formik from 'formik';
import * as yup from 'yup';
import Swal from "sweetalert2";
import parse from 'html-react-parser';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { Player, ControlBar, BigPlayButton } from 'video-react';
import 'video-react/dist/video-react.css';


const VenueDetail = () => {
    const { Formik } = formik;
    
    const schema = yup.object().shape({
        name: yup.string().required('Venue name is required'),
        permalink: yup.string().required('Permalink is required'),
        meta_key: yup.string().required('Meta keyword is required'),
        meta_desc: yup.string().required('Meta Description is required'),
        domisili: yup.string().required('Domisili is required'),
        location: yup.string().required('Address is required'),
        opening_hour: yup.string().required('Opening Hour is required'),
        lat: yup.number().required('Latitude is required'),
        lng: yup.number().required('Longitude is required'),
        phone: yup.number().required().positive().integer('Phone Number is required'),
        website: yup.string().nullable(),
        youtube: yup.string().nullable(),
        description1: yup.string().required('Description 1 cannot be empty'),
        description2: yup.string().required('Description 2 cannot be empty'),
        type: yup.string().required('Venue needs to be categorized'),
        has_barandlounge: yup.number().required('Please chooose one of the option'),
        has_hotel: yup.number().required('Please chooose one of the option'),
        has_spa: yup.number().required('Please chooose one of the option'),
        has_karaoke: yup.number().required('Please chooose one of the option'),
        has_nightclub: yup.number().required('Please chooose one of the option'),
        has_lc: yup.number().required('Please chooose one of the option'),
        has_pay: yup.number().required('Merchant class is required'),
        city_ID: yup.number().required('City needs to be selected')
    });

    const schemaFeature = yup.object().shape({
      vf_title: yup.string().required('Venue Feature title is required')
    });

    const schemaEvent = yup.object().shape({
      venue_event_title: yup.string().required('Event title is required'),
      venue_event_permalink: yup.string().required('Event permalink is required'),
      venue_event_meta_key: yup.string().required('Event meta keywords is required'),
      venue_event_meta_desc: yup.string().required('Event meta description is required'),
      venue_event_date: yup.date()
      .typeError("Please enter a valid date")
      .required("Event date is required")
      .min("1969-11-13", "Date is too early")
    });

    const schemaImage = yup.object().shape({
      file: yup.mixed().required('Image is required')
      
    });

    const schemaVideo = yup.object().shape({
      file: yup.mixed().required('Video is required')
      
    });

    const schemaMassure = yup.object().shape({
      file: yup.mixed().required('Package is required')
      
    });

    const location = useLocation();
    const data = location.state;
    const [token, setToken] = useState('');
    const [cities, setCities] = useState([]);
    const [expire, setExpire] = useState('');
    const {REACT_APP_API_URL} = process.env
    const {REACT_APP_IMAGE_URL} = process.env
    axios.defaults.baseURL = REACT_APP_API_URL;
    const history = useNavigate();

    const [image2, setImage2] = useState('');   
    const [inputVals2, setInputVals2] = useState({
        id: data.id,
        city_ID: data.city_ID,
        name: data.name,
        permalink: data.permalink,
        meta_key: data.meta_key,
        meta_desc: data.meta_desc,
        domisili: data.domisili,
        location: data.location,
        opening_hour: data.opening_hour,
        lat: data.lat,
        lng: data.lng,
        phone: data.phone,
        website: data.website,
        description1: data.description1,
        description2: data.description2,
        has_nightclub: data.has_nightclub,
        has_hotel: data.has_hotel,
        has_barandlounge: data.has_barandlounge,
        has_spa: data.has_spa,
        has_karaoke: data.has_karaoke,
        has_lc: data.has_lc,
        has_pay: data.has_pay,
        type: data.type,
        youtube: data.youtube
    });   

    const [inputVals3, setInputVals3] = useState({
      id: data.id,
      vf_title: ""
    });   
    const [featureData, setFeatureData] = useState([]);

    const [inputVals4, setInputVals4] = useState({
      id: data.id,
      venue_event_title: "",
      venue_event_permalink: "",
      venue_event_meta_key: "",
      venue_event_meta_desc: "",
      venue_event_desc: "",
      venue_event_link: "",
      venue_event_date: ""
    });   
    const [eventData, setEventData] = useState([]);
    const [image3, setImage3] = useState('');

    const [inputVals5, setInputVals5] = useState({
        id: data.id
    });   

    const [imageData, setImageData] = useState([]);
    const [image4, setImage4] = useState('');

    const [inputVals6, setInputVals6] = useState({
      id: data.id
    });   

    const [videoData, setVideoData] = useState([]);
    const [video, setVideo] = useState('');

    const [inputVals7, setInputVals7] = useState({
      id: data.id
    });   

    const [massureData, setMassureData] = useState([]);
    const [massure, setMassure] = useState('');

    const refreshToken = async () => {
        try {
            const response = await axios.get('token');
            setToken(response.data.accessToken);
            const decoded = jwtDecode(response.data.accessToken);
            // console.log('DECODED: ', decoded);
            setExpire(decoded.exp);
        } catch (error) {
            if (error.response) {
                history("/");
            }
        }
    }
  
    useEffect(() => {
        refreshToken();
        getFeatureData(data);
        getEventData(data);
        getImageData(data);
        getVideoData(data);
        getMassureData(data);
        getCity();
    }, []);
    
    const axiosJWT = axios.create();

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get('token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwtDecode(response.data.accessToken);
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const getCity = async() => {
        axiosJWT
        .get("city" , {
        headers: {
            Authorization: `Bearer ${token}`
        }
        })
        .then((result) => {
        setCities([...result.data]);
        })
        .catch((error) => console.log(error));
    } 
    
    const getFeatureData = async (data) => {
        const response = await axiosJWT.get('features/'+data.id, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        setFeatureData([...response.data]);
      }
  
      const getImageData = async (data) => {
        const response = await axiosJWT.get('venimages/'+data.id, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        setImageData([...response.data]);
      }

      const getVideoData = async (data) => {
        const response = await axiosJWT.get('venvideos/'+data.id, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        setVideoData([...response.data]);
      }

      const getMassureData = async (data) => {
        const response = await axiosJWT.get('venpacks/'+data.id, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        setMassureData([...response.data]);
      }
  
      const getEventData = async (data) => {
        const response = await axiosJWT.get('/pubeventvenue/'+data.id, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        setEventData([...response.data]);
      }

      const updateData = async (values) => {
        try {
          await axios.put('venue/'+inputVals2.id, {
            name: values.name,
            permalink: values.permalink,
            meta_key: values.meta_key,
            meta_desc: values.meta_desc,
            city_ID: values.city_ID,
            domisili: values.domisili,
            location: values.location,
            opening_hour: values.opening_hour,
            lat: values.lat,
            lng: values.lng,
            phone: values.phone,
            website: values.website,
            description1: values.description1,
            description2: values.description2,
            has_nightclub: values.has_nightclub,
            has_barandlounge: values.has_barandlounge,
            has_hotel: values.has_hotel,
            has_spa: values.has_spa,
            has_karaoke: values.has_karaoke,
            has_lc: values.has_lc,
            has_pay: values.has_pay,
            type: values.type,
            youtube: values.youtube,
            file: image2
          },  {
              headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'multipart/form-data'
              }
          }).then(res=>{
            Swal.fire({
              title: "Update successful",
              text:"Venue has been updated successfully",
              icon: "success",
              color: "#fff",
              background: "#2b3035",
              timer: 3000,
              position: 'top-end'
            });
            history('/venues')
          });
        } 
        catch (error) {
            if (error.response) {
                console.log(error.response.data.msg);
            }
        }
      }

      const deleteFeature = (data) => (e) => {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this action!",
          icon: "warning",
          color: "#fff",
          background: "#2b3035",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Delete!"
        }).then((result) => {
          if (result.isConfirmed) {
            axios.delete('features/'+data.vf_ID, {
              headers: {
                  Authorization: `Bearer ${token}`
              }
            }).then(res=>{
                getFeatureData(data);
            });
            Swal.fire({
              title: "Deletion Successful",
              text: "Features has been deleted.",
              icon: "success",
              color: "#fff",
              background: "#2b3035",
            });
          }
        })
      }  
  
      const deleteEvent = (data) => (e) => {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this action!",
          icon: "warning",
          color: "#fff",
          background: "#2b3035",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Delete!"
        }).then((result) => {
          if (result.isConfirmed) {
            axios.delete('events/'+ data.venue_event_ID, { data: { filename: data.venue_event_link } }, {
              headers: {
                  Authorization: `Bearer ${token}`
              }
            }).then(res=>{
                getEventData(data);
            });
            Swal.fire({
              title: "Deletion Successful",
              text: "Event has been deleted.",
              icon: "success",
              color: "#fff",
              background: "#2b3035",
            });
          }
        })
      }  
  
      const deleteImage = (data) => (e) => {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this action!",
          icon: "warning",
          color: "#fff",
          background: "#2b3035",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Delete!"
        }).then((result) => {
          if (result.isConfirmed) {
            axios.delete('venimages/'+ data.vi_ID, { data: { filename: data.vi_img_link } }, {
              headers: {
                  Authorization: `Bearer ${token}`
              }
            }).then(res=>{
                getImageData(data);
            });
            Swal.fire({
              title: "Deletion Successful",
              text: "Gallery Image has been deleted.",
              icon: "success",
              color: "#fff",
              background: "#2b3035",
            });
          }
        })
        
      }

      const deleteVideo = (data) => (e) => {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this action!",
          icon: "warning",
          color: "#fff",
          background: "#2b3035",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Delete!"
        }).then((result) => {
          if (result.isConfirmed) {
            axios.delete('venvideos/'+ data.vvi_ID, { data: { filename: data.vvi_vid_link } }, {
              headers: {
                  Authorization: `Bearer ${token}`
              }
            }).then(res=>{
                getVideoData(data);
            });
            Swal.fire({
              title: "Deletion Successful",
              text: "Venue Video has been deleted.",
              icon: "success",
              color: "#fff",
              background: "#2b3035",
            });
          }
        })
        
      }

      const deleteMassure = (data) => (e) => {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this action!",
          icon: "warning",
          color: "#fff",
          background: "#2b3035",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Delete!"
        }).then((result) => {
          if (result.isConfirmed) {
            axios.delete('venpacks/'+ data.vp_ID, { data: { filename: data.vp_img_link } }, {
              headers: {
                  Authorization: `Bearer ${token}`
              }
            }).then(res=>{
                getMassureData(data);
            });
            Swal.fire({
              title: "Deletion Successful",
              text: "Venue Package has been deleted.",
              icon: "success",
              color: "#fff",
              background: "#2b3035",
            });
          }
        })
        
      }

      const create_feature = async (values) => {
        //e.preventDefault();
        try {
            await axios.post('features', {
                id: inputVals3.id,
                vf_title: values.vf_title
            },  {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            Swal.fire({
              title: "Creation successful",
              text:"Features has been created successfully",
              icon: "success",
              color: "#fff",
              background: "#2b3035",
              timer: 3000,
              position: 'top-end'
            });
            getFeatureData(values);
        } catch (error) {
            if (error.response) {
                console.log(error.response.data.msg);
            }
        }
    }
  
    const create_event = async (values) => {
      //e.preventDefault();
      try {
          // console.log('INPUT VALUE: ', values)
          // console.log('IMAGE VALUE: ', image3)
          await axios.post('events', {
              id: inputVals4.id,
              venue_event_title: values.venue_event_title,
              venue_event_permalink: values.venue_event_permalink,
              venue_event_meta_key: values.venue_event_meta_key,
              venue_event_meta_desc: values.venue_event_meta_desc,
              venue_event_desc: inputVals4.venue_event_desc,
              file: image3,
              venue_event_date: values.venue_event_date
          },  {
              headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'multipart/form-data'
              }
          });
          Swal.fire({
            title: "Creation successful",
            text:"Event has been created successfully",
            icon: "success",
            color: "#fff",
            background: "#2b3035",
            timer: 3000,
            position: 'top-end'
          });
          getEventData(values);
      } catch (error) {
          if (error.response) {
              console.log(error.response.data.msg);
          }
      }
    }
  
    const create_image = async (values) => {
      //e.preventDefault();
      try {
          // console.log('INPUT VALUE: ', values.id)
          // console.log('IMAGE VALUE: ', image4)
          await axios.post('venimages', {
              id: inputVals5.id,
              file: image4
          },  {
              headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'multipart/form-data'
              }
          });
          Swal.fire({
            title: "Creation successful",
            text:"Image has been created successfully",
            icon: "success",
            color: "#fff",
            background: "#2b3035",
            timer: 3000,
            position: 'top-end'
          });
          getImageData(values);
      } catch (error) {
          if (error.response) {
              console.log(error.response.data.msg);
          }
      }
    }

    const create_video = async (values) => {
      //e.preventDefault();
      try {
          // console.log('INPUT VALUE: ', values.id)
          // console.log('IMAGE VALUE: ', image4)
          await axios.post('venvideos', {
              id: inputVals6.id,
              file: video
          },  {
              headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'multipart/form-data'
              }
          });
          Swal.fire({
            title: "Creation successful",
            text:"Video has been created successfully",
            icon: "success",
            color: "#fff",
            background: "#2b3035",
            timer: 3000,
            position: 'top-end'
          });
          getVideoData(values);
      } catch (error) {
          if (error.response) {
              console.log(error.response.data.msg);
          }
      }
    }

    const create_massure = async (values) => {
      //e.preventDefault();
      try {
          // console.log('INPUT VALUE: ', values.id)
          // console.log('IMAGE VALUE: ', image4)
          await axios.post('venpacks', {
              id: inputVals7.id,
              file: massure
          },  {
              headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'multipart/form-data'
              }
          });
          Swal.fire({
            title: "Creation successful",
            text:"Package has been created successfully",
            icon: "success",
            color: "#fff",
            background: "#2b3035",
            timer: 3000,
            position: 'top-end'
          });
          getMassureData(values);
      } catch (error) {
          if (error.response) {
              console.log(error.response.data.msg);
          }
      }
    }

    const handleEditor = (event, editor) => {
        setInputVals4({...inputVals4, venue_event_desc:editor.getData()})
    
      };


    return (
        <Container>
            <Tabs
                  defaultActiveKey="updateVenue"
                  id="justify-tab-example"
                  className="mb-3 text-white"
                  justify
                  >
                  <Tab eventKey="updateVenue" title="Update Venue Detail">
                    <Formik
                      validationSchema={schema}
                      onSubmit={values => {
                          updateData(values);
                      }}
                      initialValues={{
                        id: inputVals2.id,
                        city_ID: inputVals2.city_ID,
                        name: inputVals2.name,
                        permalink: inputVals2.permalink,
                        meta_key: inputVals2.meta_key,
                        meta_desc: inputVals2.meta_desc,
                        domisili: inputVals2.domisili,
                        location: inputVals2.location,
                        opening_hour: inputVals2.opening_hour,
                        lat: inputVals2.lat,
                        lng: inputVals2.lng,
                        phone: inputVals2.phone,
                        website: inputVals2.website,
                        description1: inputVals2.description1,
                        description2: inputVals2.description2,
                        has_nightclub: inputVals2.has_nightclub,
                        has_hotel: inputVals2.has_hotel,
                        has_barandlounge: inputVals2.has_barandlounge,
                        has_spa: inputVals2.has_spa,
                        has_karaoke: inputVals2.has_karaoke,
                        has_lc: inputVals2.has_lc,
                        has_pay: inputVals2.has_pay,
                        type: inputVals2.type,
                        youtube: inputVals2.youtube
                      }}
                      >
                      {({ handleSubmit, handleChange, setFieldValue, values, touched, errors }) => (
                          <Form noValidate onSubmit={handleSubmit}>
                          <Row className="mb-2">
                          <Form.Group as={Col} md="4" controlId="validationFormikName">
                              <Form.Label className="text-white">Venue Name</Form.Label>
                              <InputGroup hasValidation>
                                  <Form.Control
                                  type="text"
                                  placeholder="Venue Name..."
                                  aria-describedby="inputGroupPrepend"
                                  name="name"
                                  value={values.name}
                                  onChange={handleChange}
                                  isInvalid={!!errors.name}
                                  isValid={touched.name && !errors.name}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                  {errors.name}
                                  </Form.Control.Feedback>
                              </InputGroup>
                              </Form.Group>
                              <Form.Group as={Col} md="4" controlId="validationFormiDomisili">
                              <Form.Label className="text-white">Venue Domisili</Form.Label>
                              <InputGroup hasValidation>
                                  <Form.Control
                                  type="text"
                                  placeholder="Venue Domisili..."
                                  aria-describedby="inputGroupPrepend"
                                  name="domisili"
                                  value={values.domisili}
                                  onChange={handleChange}
                                  isInvalid={!!errors.domisili}
                                  isValid={touched.domisili && !errors.domisili}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                  {errors.domisili}
                                  </Form.Control.Feedback>
                              </InputGroup>
                              </Form.Group>
                              <Form.Group as={Col} md="4" controlId="validationFormikLocation">
                              <Form.Label className="text-white">Venue Location</Form.Label>
                              <InputGroup hasValidation>
                                  <Form.Control
                                  type="text"
                                  placeholder="Venue Location..."
                                  aria-describedby="inputGroupPrepend"
                                  name="location"
                                  value={values.location}
                                  onChange={handleChange}
                                  isInvalid={!!errors.location}
                                  isValid={touched.location && !errors.location}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                  {errors.location}
                                  </Form.Control.Feedback>
                              </InputGroup>
                              </Form.Group>
                          </Row>
                          <Row className="mb-2">
                              <Form.Group as={Col} md="3" controlId="validationFormikWebsite">
                              <Form.Label className="text-white">Venue Website</Form.Label>
                              <InputGroup hasValidation>
                                  <Form.Control
                                  type="text"
                                  placeholder="Venue Website Url..."
                                  aria-describedby="inputGroupPrepend"
                                  name="website"
                                  value={values.website}
                                  onChange={handleChange}
                                  isInvalid={!!errors.website}
                                  isValid={touched.website && !errors.website}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                  {errors.website}
                                  </Form.Control.Feedback>
                              </InputGroup>
                              </Form.Group>
                              <Form.Group as={Col} md="3" controlId="validationFormikWebsite">
                              <Form.Label className="text-white">Youtube Streaming ID</Form.Label>
                              <InputGroup hasValidation>
                                  <Form.Control
                                  type="text"
                                  placeholder="Youtube Livestream ID..."
                                  aria-describedby="inputGroupPrepend"
                                  name="youtube"
                                  value={values.youtube}
                                  onChange={handleChange}
                                  isInvalid={!!errors.youtube}
                                  isValid={touched.youtube && !errors.youtube}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                  {errors.youtube}
                                  </Form.Control.Feedback>
                              </InputGroup>
                              </Form.Group>
                              <Form.Group as={Col} md="3" controlId="validationFormikWebsite">
                              <Form.Label className="text-white">Permalink</Form.Label>
                              <InputGroup hasValidation>
                                  <Form.Control
                                  type="text"
                                  placeholder="Venue url permalink..."
                                  aria-describedby="inputGroupPrepend"
                                  name="permalink"
                                  value={values.permalink}
                                  onChange={handleChange}
                                  isInvalid={!!errors.permalink}
                                  isValid={touched.permalink && !errors.permalink}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                  {errors.permalink}
                                  </Form.Control.Feedback>
                              </InputGroup>
                              </Form.Group>
                              <Form.Group as={Col} md="3" controlId="validationFormikLocation">
                              <Form.Label className="text-white">Meta Keywords</Form.Label>
                              <InputGroup hasValidation>
                                  <Form.Control
                                  type="text"
                                  placeholder="Meta Keywords..."
                                  aria-describedby="inputGroupPrepend"
                                  name="meta_key"
                                  value={values.meta_key}
                                  onChange={handleChange}
                                  isInvalid={!!errors.location}
                                  isValid={touched.meta_key && !errors.meta_key}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                  {errors.meta_key}
                                  </Form.Control.Feedback>
                              </InputGroup>
                              </Form.Group> 
                          </Row>
                          <Row className="mb-2">
                              <Form.Group as={Col} md="4" controlId="validationFormiDescription1">
                              <Form.Label className="text-white">Meta Description</Form.Label>
                              <InputGroup hasValidation>
                                  <Form.Control as="textarea" rows={3}
                                  type="text"
                                  placeholder="Meta Description..."
                                  aria-describedby="inputGroupPrepend"
                                  name="meta_desc"
                                  value={values.meta_desc}
                                  onChange={handleChange}
                                  isInvalid={!!errors.meta_desc}
                                  isValid={touched.meta_desc && !errors.meta_desc}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                  {errors.meta_desc}
                                  </Form.Control.Feedback>
                              </InputGroup>
                              </Form.Group>    
                              <Form.Group as={Col} md="4" controlId="validationFormiDescription1">
                              <Form.Label className="text-white">Venue Description 1</Form.Label>
                              <InputGroup hasValidation>
                                  <Form.Control as="textarea" rows={3}
                                  type="text"
                                  placeholder="Venue Description..."
                                  aria-describedby="inputGroupPrepend"
                                  name="description1"
                                  value={values.description1}
                                  onChange={handleChange}
                                  isInvalid={!!errors.description1}
                                  isValid={touched.description1 && !errors.description1}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                  {errors.description1}
                                  </Form.Control.Feedback>
                              </InputGroup>
                              </Form.Group>
                              <Form.Group as={Col} md="4" controlId="validationFormikDescription2">
                              <Form.Label className="text-white">Venue Description 2</Form.Label>
                              <InputGroup hasValidation>
                                  <Form.Control
                                  type="text" as="textarea" rows={3}
                                  placeholder="Venue Description 2..."
                                  aria-describedby="inputGroupPrepend"
                                  name="description2"
                                  value={values.description2}
                                  onChange={handleChange}
                                  isInvalid={!!errors.description2}
                                  isValid={touched.description2 && !errors.description2}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                  {errors.description2}
                                  </Form.Control.Feedback>
                              </InputGroup>
                              </Form.Group>
                          </Row>
                          <Row className="mb-2">
                            <Form.Group as={Col} md="3" controlId="validationFormikPhone">
                              <Form.Label className="text-white">Venue Phone Number</Form.Label>
                              <Form.Control
                                  type="text"
                                  placeholder="Venue Phone Number"
                                  name="phone"
                                  value={values.phone}
                                  onChange={handleChange}
                                  isInvalid={!!errors.phone}
                                  isValid={touched.phone && !errors.phone}
                              />

                              <Form.Control.Feedback type="invalid">
                                  {errors.phone}
                              </Form.Control.Feedback>
                              </Form.Group>
                              <Form.Group as={Col} md="3" controlId="validationFormikOH">
                              <Form.Label className="text-white">Opening Hour</Form.Label>
                              <Form.Control
                                  type="text"
                                  placeholder="Venue Opening Hour"
                                  name="opening_hour"
                                  value={values.opening_hour}
                                  onChange={handleChange}
                                  isInvalid={!!errors.opening_hour}
                                  isValid={touched.opening_hour && !errors.opening_hour}
                              />

                              <Form.Control.Feedback type="invalid">
                                  {errors.opening_hour}
                              </Form.Control.Feedback>
                              </Form.Group>
                              <Form.Group as={Col} md="3" controlId="validationFormikLat">
                              <Form.Label className="text-white">Latitude</Form.Label>
                              <Form.Control
                                  type="text"
                                  placeholder="Venue Map Latitude"
                                  name="lat"
                                  value={values.lat}
                                  onChange={handleChange}
                                  isInvalid={!!errors.lat}
                                  isValid={touched.lat && !errors.lat}
                              />
                              <Form.Control.Feedback type="invalid">
                                  {errors.lat}
                              </Form.Control.Feedback>
                              </Form.Group>
                              <Form.Group as={Col} md="3" controlId="validationFormikLng">
                              <Form.Label className="text-white">Longitude</Form.Label>
                              <Form.Control
                                  type="text"
                                  placeholder="Venue Map Longitude"
                                  name="lng"
                                  value={values.lng}
                                  onChange={handleChange}
                                  isInvalid={!!errors.lng}
                                  isValid={touched.lng && !errors.lng}
                              />

                              <Form.Control.Feedback type="invalid">
                                  {errors.lng}
                              </Form.Control.Feedback>
                              </Form.Group>
                          </Row>
                          <Row className="mb-2">
                          <Form.Group as={Col} md="3" className="mb-2" controlId="validationFormikBar">
                              <Form.Label className="text-white">Bar & Lounge</Form.Label>
                              <Form.Select
                                  name="has_barandlounge"
                                  value={values.has_barandlounge}
                                  onChange={handleChange}
                                  isInvalid={!!errors.has_barandlounge}
                                  isValid={touched.has_barandlounge && !errors.has_barandlounge}
                              >
                                <option value="">Please Select</option>
                              <option value="0">No</option>
                              <option value="1">Yes</option>
                              </Form.Select>
                              <Form.Control.Feedback type="invalid">
                                  {errors.has_barandlounge}
                              </Form.Control.Feedback>
                            </Form.Group>
                          <Form.Group as={Col} md="3" className="mb-2" controlId="validationFormikHotel">
                              <Form.Label className="text-white">Hotel</Form.Label>
                              <Form.Select
                                  name="has_hotel"
                                  value={values.has_hotel}
                                  onChange={handleChange}
                                  isInvalid={!!errors.has_hotel}
                                  isValid={touched.has_hotel && !errors.has_hotel}
                              >
                                <option value="">Please Select</option>
                              <option value="0">No</option>
                              <option value="1">Yes</option>
                              </Form.Select>
                              <Form.Control.Feedback type="invalid">
                                  {errors.has_hotel}
                              </Form.Control.Feedback>
                            </Form.Group>
                          <Form.Group as={Col} md="3" className="mb-2" controlId="validationFormikNightclub">
                              <Form.Label className="text-white">Nightclub</Form.Label>
                              <Form.Select
                                  name="has_nightclub"
                                  value={values.has_nightclub}
                                  onChange={handleChange}
                                  isInvalid={!!errors.has_nightclub}
                                  isValid={touched.has_nightclub && !errors.has_nightclub}
                              >
                                <option value="">Please Select</option>
                              <option value="0">No</option>
                              <option value="1">Yes</option>
                              </Form.Select>
                              <Form.Control.Feedback type="invalid">
                                  {errors.has_nightclub}
                              </Form.Control.Feedback>
                            </Form.Group>
                          <Form.Group as={Col} md="3" className="mb-2" controlId="validationFormikSpa">
                              <Form.Label className="text-white">Spa</Form.Label>
                              <Form.Select
                                  name="has_spa"
                                  value={values.has_spa}
                                  onChange={handleChange}
                                  isInvalid={!!errors.has_spa}
                                  isValid={touched.has_spa && !errors.has_spa}
                              >
                                <option value="">Please Select</option>
                              <option value="0">No</option>
                              <option value="1">Yes</option>
                              </Form.Select>
                              <Form.Control.Feedback type="invalid">
                                  {errors.has_spa}
                              </Form.Control.Feedback>
                            </Form.Group>
                          <Form.Group as={Col} md="3" className="mb-2" controlId="validationFormikKaraoke">
                              <Form.Label className="text-white">Karaoke</Form.Label>
                              <Form.Select
                                  name="has_karaoke"
                                  value={values.has_karaoke}
                                  onChange={handleChange}
                                  isInvalid={!!errors.has_karaoke}
                                  isValid={touched.has_karaoke && !errors.has_karaoke}
                              >
                                <option value="">Please Select</option>
                              <option value="0">No</option>
                              <option value="1">Yes</option>
                              </Form.Select>
                              <Form.Control.Feedback type="invalid">
                                  {errors.has_karaoke}
                              </Form.Control.Feedback>
                            </Form.Group>
                          <Form.Group as={Col} md="3" className="mb-2" controlId="validationFormikLc">
                              <Form.Label className="text-white">Companion</Form.Label>
                              <Form.Select
                                  name="has_lc"
                                  value={values.has_lc}
                                  onChange={handleChange}
                                  isInvalid={!!errors.has_lc}
                                  isValid={touched.has_lc && !errors.has_lc}
                              >
                                <option value="">Please Select</option>
                              <option value="0">No</option>
                              <option value="1">Yes</option>
                              </Form.Select>
                              <Form.Control.Feedback type="invalid">
                                  {errors.has_lc}
                              </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="3" className="mb-2" controlId="validationFormikType">
                              <Form.Label className="text-white">Type</Form.Label>
                              <Form.Select
                                  name="type"
                                  value={values.type}
                                  onChange={handleChange}
                                  isInvalid={!!errors.type}
                                  isValid={touched.type && !errors.type}
                              >
                              <option value="">Please Select</option>
                              <option value="disco">Nightclub</option>
                              <option value="hotel">Hotel</option>
                              <option value="karaoke">Karaoke</option>
                              <option value="spa">Spa</option>
                              <option value="bar">Bar & Lounge</option>
                              </Form.Select>
                              <Form.Control.Feedback type="invalid">
                                  {errors.type}
                              </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="3" className="mb-2" controlId="validationFormikPay">
                              <Form.Label className="text-white">Merchant Class</Form.Label>
                              <Form.Select
                                  name="has_pay"
                                  value={values.has_pay}
                                  onChange={handleChange}
                                  isInvalid={!!errors.has_pay}
                                  isValid={touched.has_pay && !errors.has_pay}
                              >
                              <option value="">Please Select</option>
                              <option value="0">Normal</option>
                              <option value="1">Bronze</option>
                              <option value="2">Silver</option>
                              <option value="3">Ruby</option>
                              <option value="4">Gold</option>
                              </Form.Select>
                              <Form.Control.Feedback type="invalid">
                                  {errors.has_pay}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Row>
                          <Row>
                          <Form.Group as={Col} md="6" className="mb-2" controlId="validationFormikPay">
                              <Form.Label className="text-white">City Name</Form.Label>
                              <Form.Select
                                  name="city_ID"
                                  value={values.city_ID}
                                  onChange={handleChange}
                                  isInvalid={!!errors.city_ID}
                                  isValid={touched.city_ID && !errors.city_ID}
                              >
                              <option value="">Please Select</option>
                              {cities.map((option, i) => {
                                return (
                                  <option value={option.city_ID} key={i}>
                                    {option.city_name}
                                  </option>
                                ) ; 
                              })}
                              </Form.Select>
                              <Form.Control.Feedback type="invalid">
                                  {errors.city_ID}
                              </Form.Control.Feedback>
                            </Form.Group>
                          <Form.Group as={Col} md="6" controlId="formFile" className="mb-3">
                              <Form.Label className="text-white">Image</Form.Label>
                              <Form.Control type="file" name="file" onChange={(e) => setImage2(e.target.files[0])}/>
                          </Form.Group>
                          </Row>
                          <Button type="submit">Update Venues</Button>
                          </Form>
                      )}
                      </Formik>
                  </Tab>
                  <Tab eventKey="featureVenue" title="Venue Features">
                    <Row>
                      <Formik 
                        validationSchema={schemaFeature}
                        onSubmit={values => {
                          create_feature(values);
                        }}
                        initialValues={{
                            id: inputVals3.id,
                            vf_title: ''
                        }}>
                        {({ handleSubmit, handleChange, values, touched, errors }) => (
                        <Form noValidate onSubmit={handleSubmit}>  
                        <Form.Group as={Col} md="12" controlId="validationFormikName">
                          <InputGroup hasValidation className="mb-3">
                            <Button variant="outline-success" type="submit">
                              Add Feature
                            </Button>
                            <Form.Control
                              type="text"
                              placeholder="Venue Feature Title..."
                              aria-describedby="inputGroupPrepend"
                              name="vf_title"
                              value={values.vf_title}
                                  onChange={handleChange}
                                  isInvalid={!!errors.vf_title}
                                  isValid={touched.vf_title && !errors.vf_title}
                            />
                            <Form.Control.Feedback type="invalid">
                                  {errors.vf_title}
                            </Form.Control.Feedback>
                          </InputGroup>
                          </Form.Group>
                        </Form>
                        )}
                      </Formik>
                    </Row>
                    <Row>
                      <Table striped bordered hover variant="dark" responsive>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Feature Title</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                        {featureData.length > 0 ? (
                            featureData.map((data, i) => (
                              <tr key={i} style={{ background: '#fff' }}>
                                <td>{ i + 1}</td>
                                <td>{data.vf_title}</td>
                                <td>
                                 <ButtonGroup >
                                    <Button variant="outline-danger" className="me-2" onClick={deleteFeature(data)}>Delete</Button>
                                  </ButtonGroup>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="7">No Features found</td>
                            </tr>
                          )}
                        </tbody>
                      </Table>       
                    </Row>
                  </Tab>
                  <Tab eventKey="galleryVenue" title="Venue Gallery">
                  <Row>
                    <Formik 
                        validationSchema={schemaImage}
                        onSubmit={values => {
                          create_image(values);
                        }}
                        initialValues={{
                            id: inputVals5.id,
                            file: ''
                        }}>
                        {({ handleSubmit, handleChange, setFieldValue, touched, errors }) => (
                        <Form noValidate onSubmit={handleSubmit} encType="mu"> 
                          <Row className="mb-3">
                            <Form.Group as={Col} md="12" controlId="formFile" className="mb-3">
                                  <Form.Label className="text-white">Image</Form.Label>
                                  <InputGroup hasValidation>
                                    <Form.Control type="file" name="file" 
                                    // onChange={(e) => setImage3(e.target.files[0])}
                                    onChange={(event) => {
                                      setFieldValue("file", event.target.files[0]);
                                      setImage4(event.target.files[0]);
                                    }}
                                    isInvalid={!!errors.file}
                                    isValid={touched.file && !errors.file}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                    {errors.file}
                                    </Form.Control.Feedback>
                                  </InputGroup>
                            </Form.Group>
                            
                          </Row>
                          <Row className="m-0 mb-3">
                          <Button variant="outline-success" type="submit">Add Image</Button>
                          </Row>
                        </Form>
                        )}
                      </Formik>
                    </Row>
                    <Row> 
                      <Table striped bordered hover variant="dark" responsive>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Gallery Image</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                        {imageData.length > 0 ? (
                            imageData.map((data, i) => (
                              <tr key={i} style={{ background: '#fff' }}>
                                <td>{ i + 1}</td>
                                <td>
                                    <Figure.Image width={171}
                                            height={180}
                                            src={REACT_APP_IMAGE_URL + 'uploads/venue/gallery/' + data.vi_img_link} alt={data.vi_img_link}>
                                    </Figure.Image>
                                </td>
                                <td>
                                 <ButtonGroup >
                                    <Button variant="outline-danger" className="me-2" onClick={deleteImage(data)}>Delete</Button>
                                  </ButtonGroup>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="7">No Images found</td>
                            </tr>
                          )}
                        </tbody>
                      </Table>         
                    </Row>        

                  </Tab>
                  <Tab eventKey="videoVenue" title="Venue Video">
                  <Row>
                    <Formik 
                        validationSchema={schemaVideo}
                        onSubmit={values => {
                          create_video(values);
                        }}
                        initialValues={{
                            id: inputVals6.id,
                            file: ''
                        }}>
                        {({ handleSubmit, handleChange, setFieldValue, touched, errors }) => (
                        <Form noValidate onSubmit={handleSubmit} encType="mu"> 
                          <Row className="mb-3">
                            <Form.Group as={Col} md="12" controlId="formFile" className="mb-3">
                                  <Form.Label className="text-white">Video</Form.Label>
                                  <InputGroup hasValidation>
                                    <Form.Control type="file" name="file" 
                                    // onChange={(e) => setImage3(e.target.files[0])}
                                    onChange={(event) => {
                                      setFieldValue("file", event.target.files[0]);
                                      setVideo(event.target.files[0]);
                                    }}
                                    isInvalid={!!errors.file}
                                    isValid={touched.file && !errors.file}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                    {errors.file}
                                    </Form.Control.Feedback>
                                  </InputGroup>
                            </Form.Group>
                            
                          </Row>
                          <Row className="m-0 mb-3">
                          <Button variant="outline-success" type="submit">Add Video</Button>
                          </Row>
                        </Form>
                        )}
                      </Formik>
                    </Row>
                    <Row> 
                      <Table striped bordered hover variant="dark" responsive>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Venue Video</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                        {videoData.length > 0 ? (
                            videoData.map((data, i) => (
                              <tr key={i} style={{ background: '#fff' }}>
                                <td>{ i + 1}</td>
                                <td>
                                  <Player src={REACT_APP_IMAGE_URL + 'streamvideos/' + data.vvi_ID}>
                                    <ControlBar autoHide={false} className="my-class" />
                                    <BigPlayButton position="center" />
                                  </Player>
                                </td>
                                <td>
                                 <ButtonGroup >
                                    <Button variant="outline-danger" className="me-2" onClick={deleteVideo(data)}>Delete</Button>
                                  </ButtonGroup>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="7">No Videos found</td>
                            </tr>
                          )}
                        </tbody>
                      </Table>         
                    </Row>        

                  </Tab>

                  <Tab eventKey="massureVenue" title="Venue Packages">
                  <Row>
                    <Formik 
                        validationSchema={schemaMassure}
                        onSubmit={values => {
                          create_massure(values);
                        }}
                        initialValues={{
                            id: inputVals7.id,
                            file: ''
                        }}>
                        {({ handleSubmit, handleChange, setFieldValue, touched, errors }) => (
                        <Form noValidate onSubmit={handleSubmit} encType="mu"> 
                          <Row className="mb-3">
                            <Form.Group as={Col} md="12" controlId="formFile" className="mb-3">
                                  <Form.Label className="text-white">Packages / Massure</Form.Label>
                                  <InputGroup hasValidation>
                                    <Form.Control type="file" name="file" 
                                    // onChange={(e) => setImage3(e.target.files[0])}
                                    onChange={(event) => {
                                      setFieldValue("file", event.target.files[0]);
                                      setMassure(event.target.files[0]);
                                    }}
                                    isInvalid={!!errors.file}
                                    isValid={touched.file && !errors.file}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                    {errors.file}
                                    </Form.Control.Feedback>
                                  </InputGroup>
                            </Form.Group>
                            
                          </Row>
                          <Row className="m-0 mb-3">
                          <Button variant="outline-success" type="submit">Add Packages/Massure</Button>
                          </Row>
                        </Form>
                        )}
                      </Formik>
                    </Row>
                    <Row> 
                      <Table striped bordered hover variant="dark" responsive>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Venue Packages</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                        {massureData.length > 0 ? (
                            massureData.map((data, i) => (
                              <tr key={i} style={{ background: '#fff' }}>
                                <td>{ i + 1}</td>
                                <td>
                                    <Figure.Image width={171}
                                            height={180}
                                            src={REACT_APP_IMAGE_URL + 'uploads/venue/packages/' + data.vp_img_link} alt={data.vp_img_link}>
                                    </Figure.Image>
                                </td>
                                <td>
                                 <ButtonGroup >
                                    <Button variant="outline-danger" className="me-2" onClick={deleteMassure(data)}>Delete</Button>
                                  </ButtonGroup>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="7">No Packages found</td>
                            </tr>
                          )}
                        </tbody>
                      </Table>         
                    </Row>        

                  </Tab>        

                  <Tab eventKey="eventVenue" title="Venue Events">
                    <Row>
                    <Formik 
                        validationSchema={schemaEvent}
                        onSubmit={values => {
                          create_event(values);
                        }}
                        initialValues={{
                            id: inputVals4.id,
                            venue_event_title: '',
                            venue_event_permalink: '',
                            venue_event_meta_key: '',
                            venue_event_meta_desc: '',
                            venue_event_date: ''
                        }}>
                        {({ handleSubmit, handleChange, values, touched, errors }) => (
                        <Form noValidate onSubmit={handleSubmit}> 
                          <Row className="mb-3">
                            <Form.Group as={Col} md="3" controlId="validationFormikName">
                                <Form.Label className="text-white">Title</Form.Label>
                                <InputGroup hasValidation>
                                    <Form.Control
                                    type="text"
                                    placeholder="Event Title..."
                                    aria-describedby="inputGroupPrepend"
                                    name="venue_event_title"
                                    value={values.venue_event_title}
                                    onChange={handleChange}
                                    isInvalid={!!errors.venue_event_title}
                                    isValid={touched.venue_event_title && !errors.venue_event_title}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                    {errors.venue_event_title}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group as={Col} md="3" controlId="validationFormikName">
                                <Form.Label className="text-white">Date</Form.Label>
                                <InputGroup hasValidation>
                                    <Form.Control
                                    type="text"
                                    placeholder="yyyy-MM-dd"
                                    aria-describedby="inputGroupPrepend"
                                    name="venue_event_date"
                                    value={values.venue_event_date}
                                    onChange={handleChange}
                                    isInvalid={!!errors.venue_event_date}
                                    isValid={touched.venue_event_date && !errors.venue_event_date}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                    {errors.venue_event_date}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group as={Col} md="3" controlId="validationFormikName">
                                <Form.Label className="text-white">Permalink</Form.Label>
                                <InputGroup hasValidation>
                                    <Form.Control
                                    type="text"
                                    placeholder="Event permalink..."
                                    aria-describedby="inputGroupPrepend"
                                    name="venue_event_permalink"
                                    value={values.venue_event_permalink}
                                    onChange={handleChange}
                                    isInvalid={!!errors.venue_event_date}
                                    isValid={touched.venue_event_permalink && !errors.venue_event_permalink}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                    {errors.venue_event_permalink}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group as={Col} md="3" controlId="formFile" className="mb-3">
                                  <Form.Label className="text-white">Image</Form.Label>
                                  <Form.Control type="file" name="file" onChange={(e) => setImage3(e.target.files[0])}/>
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="validationFormikName">
                                <Form.Label className="text-white">Meta Keywords</Form.Label>
                                <InputGroup hasValidation>
                                    <Form.Control
                                    type="text"
                                    placeholder="Event Keywords..."
                                    aria-describedby="inputGroupPrepend"
                                    name="venue_event_meta_key"
                                    value={values.venue_event_meta_key}
                                    onChange={handleChange}
                                    isInvalid={!!errors.venue_event_meta_key}
                                    isValid={touched.venue_event_meta_key && !errors.venue_event_meta_key}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                    {errors.venue_event_meta_key}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="validationFormiDescription1">
                              <Form.Label className="text-white">Event Meta Description</Form.Label>
                              <InputGroup hasValidation>
                                  <Form.Control as="textarea" rows={3}
                                  type="text"
                                  placeholder="Meta Description..."
                                  aria-describedby="inputGroupPrepend"
                                  name="venue_event_meta_desc"
                                  value={values.venue_event_meta_desc}
                                  onChange={handleChange}
                                  isInvalid={!!errors.venue_event_meta_desc}
                                  isValid={touched.venue_event_meta_desc && !errors.venue_event_meta_desc}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                  {errors.venue_event_meta_desc}
                                  </Form.Control.Feedback>
                              </InputGroup>

                            </Form.Group>
                            <Form.Group as={Col} md="12" controlId="validationFormiDescription1">
                              <Form.Label className="text-white">Event Description</Form.Label>
                              {/* <InputGroup hasValidation>
                                  <Form.Control as="textarea" rows={3}
                                  type="text"
                                  placeholder="Event Description..."
                                  aria-describedby="inputGroupPrepend"
                                  name="venue_event_desc"
                                  value={values.venue_event_desc}
                                  onChange={handleChange}
                                  isInvalid={!!errors.venue_event_desc}
                                  isValid={touched.venue_event_desc && !errors.venue_event_desc}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                  {errors.venue_event_desc}
                                  </Form.Control.Feedback>
                              </InputGroup> */}
                              <CKEditor
                                    editor={ ClassicEditor }
                                    data=''
                                    onChange={handleEditor}
                                />
                            </Form.Group>
                          </Row>
                          <Row className="m-0 mb-3">
                          <Button variant="outline-success" type="submit">Create Event</Button>
                          </Row>
                        </Form>
                        )}
                      </Formik>
                    </Row>
                    <Row> 
                      <Table striped bordered hover variant="dark" responsive>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Event Title</th>
                            <th>Event Permalink</th>
                            <th>Meta Keywords</th>
                            <th>Event Description</th>
                            <th>Event Date</th>
                            <th>Event Image</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                        {eventData.length > 0 ? (
                            eventData.map((data, i) => (
                              <tr key={i} style={{ background: '#fff' }}>
                                <td>{ i + 1}</td>
                                <td>{data.venue_event_title}</td>
                                <td>{data.venue_event_permalink}</td>
                                <td>{data.venue_event_meta_key}</td>
                                <td>{parse(data.venue_event_desc)}</td>
                                <td>{data.venue_event_date}</td>
                                <td>
                                    <Figure.Image width={171}
                                            height={180}
                                            src={REACT_APP_IMAGE_URL + 'uploads/venue/events/' + data.venue_event_link} alt={data.venue_event_title}>
                                    
                                    </Figure.Image>
                                </td>
                                <td>
                                 <ButtonGroup >
                                    <Button variant="outline-danger" className="me-2" onClick={deleteEvent(data)}>Delete</Button>
                                  </ButtonGroup>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="7">No Events found</td>
                            </tr>
                          )}
                        </tbody>
                      </Table>         
                    </Row>
                  </Tab>
          </Tabs>
        </Container>
    )

  
}

export default VenueDetail