import React, {useState, useEffect} from "react"
import { Container, Row, Col, Button, Figure, Tabs, Tab, Table, Form, Modal, ButtonGroup } from "react-bootstrap"
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import CustomPagination from './CustomPagination';
import Swal from "sweetalert2";
import { Placeholder } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";

const Cities = () => {
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [apiData, setApiData] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const {REACT_APP_API_URL} = process.env
    const {REACT_APP_IMAGE_URL} = process.env
    axios.defaults.baseURL = REACT_APP_API_URL;
    const history = useNavigate();

    const [image, setImage] = useState('');
    const [inputVals, setInputVals] = useState({
        city_name: ""
       });

    const [image2, setImage2] = useState('');
    const [inputVals2, setInputVals2] = useState({
        city_ID: "",
        city_name: ""
        });   
    
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (data) => { 
      setInputVals2({...inputVals2, 
        city_ID: data.city_ID,
        city_name: data.city_name
      })
      setShow(true);
    }      
    const [msg, setMsg] = useState('');   

    const [searchFilter, setSearchFilter] = useState(''); // filter the search
    const [currentPage, setCurrentPage] = useState(1); // set the current page
    const pageSize = 20; // show row in table

    useEffect(() => {
        refreshToken();
        getApiData();
      }, [searchFilter]);

      const create_city = async (e) => {
        e.preventDefault();
        try {
            console.log('INPUT VALUE: ', inputVals)
            console.log('IMAGE VALUE: ', image)
            await axios.post('city', {
                city_name: inputVals.city_name,
                file: image
            },  {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });
            Swal.fire({
              title: "Creation successful",
              text:"New City has been created successfully",
              icon: "success",
              color: "#fff",
              background: "#2b3035",
              timer: 3000,
              position: 'top-end'
            });
            getApiData();
        } catch (error) {
            if (error.response) {
                setMsg(error.response.data.msg);
            }
        }
    }

      const handleFilter = (e) => {
        setSearchFilter(e.target.value);
      };
    
      const filteredData = apiData.filter(
        (item) =>
          item.city_name.toLowerCase().includes(searchFilter.toLowerCase()) 
      );
    
      const paginatedData = filteredData.slice(
        (currentPage - 1) * pageSize,
        currentPage * pageSize
      );

      const refreshToken = async () => {
        try {
            const response = await axios.get('token');
            setToken(response.data.accessToken);
            const decoded = jwtDecode(response.data.accessToken);
            // console.log('DECODED: ', decoded);
            setExpire(decoded.exp);
        } catch (error) {
            if (error.response) {
                history("/");
            }
        }
    }
 
    const axiosJWT = axios.create();
 
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get('token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwtDecode(response.data.accessToken);
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });
 
    const getApiData = async () => {
        const response = await axiosJWT.get('city', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        setApiData([...response.data]);
        setLoading(false);
        // console.log('USERS: ', response.data);
    }

    const deleteData = (data) => (e) => {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this action!",
          icon: "warning",
          color: "#fff",
          background: "#2b3035",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Delete!"
        }).then((result) => {
          if (result.isConfirmed) {
            axios.delete('city/'+data.city_ID, { data: { filename: data.city_img_link } }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(res=>{
                getApiData();
            });
            Swal.fire({
              title: "Deletion Successful",
              text: "City has been deleted.",
              icon: "success",
              color: "#fff",
              background: "#2b3035",
            });
          }
        })
        
      }

    const updateData = (data) => (e) => {
      axios.put('city/'+data, {
              city_name: inputVals2.city_name,
              file: image2
          },  {
              headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'multipart/form-data'
              }
          }).then(res=>{
          setShow(false);  
          getApiData();
      });
      // console.log("Value: ", inputVals2);
      // console.log("Data: ", data);
      // console.log("Image:", image2);
    }
    
    const placeholder = [
      <tr>
        <td><Placeholder animation="glow">
              <Placeholder xs={12} />
            </Placeholder></td>
        <td><Placeholder animation="glow">
              <Placeholder xs={12} />
        </Placeholder></td>
        <td><Placeholder animation="glow">
              <Placeholder xs={12} />
            </Placeholder></td>
        <td><Placeholder animation="glow">
              <Placeholder xs={12} />
            </Placeholder></td>
        
      </tr>
    ]

    const listPlaceholder = [];

  for (let i = 0; i < 10; i++)
  {
    listPlaceholder.push(placeholder);
  }

  if (isLoading) {
    return (
      <>
      <Container>
        <Tabs
                id="justify-tab-example"
                className="mb-3 text-white"
                >
                <Tab eventKey="venues" title="Venues">
                    <Row>
                      <Col>
                      <Table striped bordered hover variant="dark" responsive>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>City Name</th>
                            <th>Image</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {listPlaceholder}
                            <tr>
                              <td colSpan="4" className="text-center">
                                <Spinner animation="border" role="status" size="sm"/>
                                <Spinner animation="border" role="status" size="sm"/>  
                                <Spinner animation="border" role="status" size="sm" className="me-2"/>Loading Data...
                                </td>
                            </tr>
                        </tbody>
                      </Table>
                      </Col>
                    </Row>
                </Tab>
        </Tabs>
      </Container>
      </>
    )
  }  

  return (
    <>
      <Container>
        <Tabs
                defaultActiveKey="cities"
                id="justify-tab-example"
                className="mb-3 text-white"
                justify
                >
                <Tab eventKey="cities" title="Cities">
                    <Row>
                      <Col>
                      <input
                        style={{ width: "200px" }}
                        className='form-control mb-2'
                        placeholder='Search'
                        value={searchFilter}
                        onChange={handleFilter}
                      />
                      <Table striped bordered hover variant="dark" responsive>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>City Name</th>
                            <th>Image</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                        {paginatedData.length > 0 ? (
                            paginatedData.map((data, i) => (
                              <tr key={i} style={{ background: '#fff' }}>
                                <td>{(currentPage - 1) * pageSize + i + 1}</td>
                                <td>{data.city_name}</td>
                                <td>
                                    <Figure.Image width={171}
                                            height={180}
                                            src={REACT_APP_IMAGE_URL + 'uploads/city/' + data.city_img_link} alt={data.blog_title}>
                                    
                                    </Figure.Image>
                                </td>
                                <td>
                                  <ButtonGroup >
                                    <Button variant="outline-danger" className="me-2" onClick={deleteData(data)}>Delete</Button>
                                    <Button variant="outline-primary" onClick={() => handleShow(data)}>Update</Button>
                                  </ButtonGroup>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <>
                            <tr>
                               <td colSpan="4" className="text-center">
                                No Data Found...
                                </td>
                            </tr>
                            </>
                          )}
                        </tbody>
                      </Table>
                      </Col>
                    </Row>
                    <Row className="text-center">
                      <Col>
                      <div className='d-flex justify-content-md-center'>
                      {filteredData.length > 0 &&
                        <>
                          <CustomPagination
                            itemsCount={filteredData.length}
                            itemsPerPage={pageSize}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            alwaysShown={true}
                          />
                        </>
                      }
                      </div>
                      </Col>
                      </Row>
                </Tab>
                <Tab eventKey="create" title="Add Cities">
                    <Row>
                        <Form onSubmit={create_city} className="box" encType="multipart/form-data">
                            <p className="has-text-centered">{msg}</p>
                            <Form.Group className="mb-3" controlId="cityname.ControlInput1">
                                <Form.Label className="text-white">City Name</Form.Label>
                                <Form.Control type="text" placeholder="input city name..." 
                                value={inputVals.city_name} onChange={(e) => setInputVals({...inputVals, city_name:e.target.value})}/>
                            </Form.Group>
                            <Form.Group controlId="formFile" className="mb-3">
                                <Form.Label className="text-white">Image</Form.Label>
                                <Form.Control type="file" name="file" onChange={(e) => setImage(e.target.files[0])}/>
                            </Form.Group>
                            
                            <Button variant="outline-primary" type="submit">Create</Button>
                            
                        </Form>
                     </Row>
                </Tab>
        </Tabs>
      </Container>
      <Modal
        show={show}
        fullscreen={true}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header style={{backgroundColor: "#212529"}}>
          <Modal.Title className="text-white">Update City</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{backgroundColor: '#2c3034'}}>
            <Form className="box" encType="multipart/form-data">
              <p className="has-text-centered">{msg}</p>
              <Form.Group className="mb-3" controlId="cityname.ControlInput1">
                  <Form.Label className="text-white">City Name</Form.Label>
                  <Form.Control type="text" placeholder="input city name..." 
                  value={inputVals2.city_name} onChange={(e) => setInputVals2({...inputVals2, city_name:e.target.value})}/>
              </Form.Group>
              <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label className="text-white">Image</Form.Label>
                  <Form.Control type="file" name="file" onChange={(e) => setImage2(e.target.files[0])}/>
              </Form.Group>
            </Form>
        </Modal.Body>
        <Modal.Footer style={{backgroundColor: "#212529"}}>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={updateData(inputVals2.city_ID)}>Update</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Cities