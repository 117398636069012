import React, {useState, useEffect} from "react"
import { Container, Row, Col, Button, Figure, Tabs, Tab, Table, ButtonGroup, InputGroup, Form } from "react-bootstrap"
import axios from "axios";
import { useNavigate, useLocation } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import CustomPagination from './CustomPagination';
import Swal from "sweetalert2";
import { Placeholder } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";

const Venues = () => {

    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [apiData, setApiData] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const location = useLocation();
    const data = location.state;
    const {REACT_APP_API_URL} = process.env;
    const {REACT_APP_IMAGE_URL} = process.env;
    axios.defaults.baseURL = REACT_APP_API_URL;
    const history = useNavigate();

    const [searchFilter, setSearchFilter] = useState(data != null ? data.name : ''); // filter the search
    const [currentPage, setCurrentPage] = useState(1); // set the current page
    const pageSize = 20; // show row in table

    const handleFilter = (e) => {
      setSearchFilter(e.target.value);
    };
  
    const filteredData = apiData.filter(
      (item) =>
        item.name.toLowerCase().includes(searchFilter.toLowerCase()) 
    );
  
    const paginatedData = filteredData.slice(
      (currentPage - 1) * pageSize,
      currentPage * pageSize
    );

    const refreshToken = async () => {
      try {
          const response = await axios.get('token');
          setToken(response.data.accessToken);
          const decoded = jwtDecode(response.data.accessToken);
          // console.log('DECODED: ', decoded);
          setExpire(decoded.exp);
      } catch (error) {
          if (error.response) {
              history("/");
          }
      }
    }

    useEffect(() => {
        refreshToken();
        getApiData();
      }, [searchFilter]);

    const axiosJWT = axios.create();
 
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get('token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwtDecode(response.data.accessToken);
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });
 
    const getApiData = async () => {
       const response = await axiosJWT.get('venue', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        setApiData([...response.data]);
        setLoading(false);
    }

    const deleteData = (data) => (e) => {
        // console.log(data);
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this action!",
          icon: "warning",
          color: "#fff",
          background: "#2b3035",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Delete!"
        }).then((result) => {
          if (result.isConfirmed) {
            axios.delete('venue/'+data.id, { data: { filename: data.main_image } }, {
              headers: {
                  Authorization: `Bearer ${token}`
              }
            }).then(res=>{
                getApiData();
            });
            Swal.fire({
              title: "Deletion Successful",
              text: "Venue has been deleted.",
              icon: "success",
              color: "#fff",
              background: "#2b3035",
            });
          }
        })
        
      }

  const placeholder = [
    <tr>
      <td><Placeholder animation="glow">
            <Placeholder xs={12} />
          </Placeholder></td>
      <td><Placeholder animation="glow">
            <Placeholder xs={12} />
      </Placeholder></td>
      <td><Placeholder animation="glow">
            <Placeholder xs={12} />
          </Placeholder></td>
      <td><Placeholder animation="glow">
            <Placeholder xs={12} />
          </Placeholder></td>
      <td><Placeholder animation="glow">
            <Placeholder xs={12} />
          </Placeholder></td>
      <td><Placeholder animation="glow">
            <Placeholder xs={12} />
          </Placeholder></td>
      <td><Placeholder animation="glow">
            <Placeholder xs={12} />
          </Placeholder></td>
      <td><Placeholder animation="glow">
        <Placeholder xs={12} />
      </Placeholder></td>    
    </tr>
  ]

  const listPlaceholder = [];

  for (let i = 0; i < 10; i++)
  {
    listPlaceholder.push(placeholder);
  }

  const venueDetail = (data) => (e) => {
    history('/venuesDetail', {state: data});
  }

  if (isLoading) {
    return (
      <>
        <Container>
          <Tabs
                  defaultActiveKey="venues"
                  id="justify-tab-example"
                  className="mb-3 text-white"
                  >
                  <Tab eventKey="venues" title="Venues">
                      <Row>
                        <Col>
                        <Table striped bordered hover variant="dark" responsive>
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Name</th>
                              <th>Permalink</th>
                              <th>Meta Key</th>
                              <th>Meta Description</th>
                              <th>Type</th>
                              <th>Image</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {listPlaceholder}
                              <tr>
                                <td colSpan="8" className="text-center">
                                  <Spinner animation="border" role="status" size="sm"/>
                                  <Spinner animation="border" role="status" size="sm"/>  
                                  <Spinner animation="border" role="status" size="sm" className="me-2"/>Loading Data...
                                  </td>
                              </tr>
                          </tbody>
                        </Table>
                        </Col>
                      </Row>
                  </Tab>
          </Tabs>
        </Container>
      </>
    )
  }

  return (
   <>
      <Container>
        <Tabs
                defaultActiveKey="venues"
                id="justify-tab-example"
                className="mb-3 text-white"
                >
                <Tab eventKey="venues" title="Venues">
                    <Row>
                      <Col>
                      <InputGroup className="mb-3">
                        <Form.Control
                          placeholder='Search Venue'
                          value={searchFilter}
                          onChange={handleFilter}
                          aria-label="Venue Search"
                          aria-describedby="basic-addon2"
                        />
                      </InputGroup>
                      <Table striped bordered hover variant="dark" responsive>
                        <thead>
                          <tr>
                              <th>#</th>
                              <th>Name</th>
                              <th>Permalink</th>
                              <th>Meta Key</th>
                              <th>Meta Description</th>
                              <th>Type</th>
                              <th>Image</th>
                              <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                        {paginatedData.length > 0 ? (
                            paginatedData.map((data, i) => (
                              <tr key={i} style={{ background: '#fff' }}>
                                <td>{(currentPage - 1) * pageSize + i + 1}</td>
                                <td>{data.name + ' - ' + data.city_name}</td>
                                <td>{data.permalink}</td>
                                <td>{data.meta_key}</td>
                                <td>{data.meta_desc}</td>
                                <td>{data.type}</td>
                                <td>
                                    <Figure.Image width={171}
                                            height={180}
                                            src={REACT_APP_IMAGE_URL + 'uploads/venue/' + data.main_image} alt={data.nama}>
                                    
                                    </Figure.Image>
                                </td>
                                <td>
                                 <ButtonGroup >
                                    <Button variant="outline-danger" className="me-2" onClick={deleteData(data)}>Delete</Button>
                                    <Button variant="outline-primary" onClick={venueDetail(data)}>View Details</Button>
                                  </ButtonGroup>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <>
                            <tr>
                               <td colSpan="8" className="text-center">
                                No Data Found...
                                </td>
                            </tr>
                            </>
                          )}
                        </tbody>
                      </Table>
                      </Col>
                    </Row>
                    <Row className="text-center">
                      <Col>
                      <div className='d-flex justify-content-md-center'>
                      {filteredData.length > 0 &&
                        <>
                          <CustomPagination
                            itemsCount={filteredData.length}
                            itemsPerPage={pageSize}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            alwaysShown={true}
                          />
                        </>
                      }
                      </div>
                      </Col>
                      </Row>
                </Tab>
        </Tabs>
      </Container>
      
    </>
  )
}

export default Venues