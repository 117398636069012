import React, {useState, useEffect} from "react"
import { Container, Row, Col, Button, Figure, Tabs, Tab, Table, Form, ButtonGroup } from "react-bootstrap"
import axios from "axios";
import { useLocation, useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

import InputGroup from 'react-bootstrap/InputGroup';
import * as formik from 'formik';
import * as yup from 'yup';
import Swal from "sweetalert2";
import parse from 'html-react-parser';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { Player, ControlBar, BigPlayButton } from 'video-react';
import 'video-react/dist/video-react.css';


const UserDetail = () => {
    const { Formik } = formik;

    const schemaVenueUser = yup.object().shape({
        id: yup.number().required('Venue ID is required')
      });
    
    const location = useLocation();
    const data = location.state;
    const [token, setToken] = useState('');
    const [venue, setVenue] = useState([]);
    const [expire, setExpire] = useState('');
    const {REACT_APP_API_URL} = process.env
    const {REACT_APP_IMAGE_URL} = process.env
    axios.defaults.baseURL = REACT_APP_API_URL;
    const history = useNavigate();

    const [venueData, setVenueData] = useState([]);

    const refreshToken = async () => {
        try {
            const response = await axios.get('token');
            setToken(response.data.accessToken);
            const decoded = jwtDecode(response.data.accessToken);
            // console.log('DECODED: ', decoded);
            setExpire(decoded.exp);
        } catch (error) {
            if (error.response) {
                history("/");
            }
        }
    }
  
    useEffect(() => {
        refreshToken();
        getVenueUser(data);
        getVenue()
       }, []);
    
    const axiosJWT = axios.create();

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get('token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwtDecode(response.data.accessToken);
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    
    const getVenueUser = async (data) => {
        const response = await axiosJWT.get('venueUser/'+data.pID, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        setVenueData([...response.data]);
      }
    
    const getVenue = async() => {
        axiosJWT
        .get("pubvenue" , {
        headers: {
            Authorization: `Bearer ${token}`
        }
        })
        .then((result) => {
        setVenue([...result.data]);
        })
        .catch((error) => console.log(error));
    } 
      
  
      const deleteVenueUser = (data) => (e) => {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this action!",
          icon: "warning",
          color: "#fff",
          background: "#2b3035",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Delete!"
        }).then((result) => {
          if (result.isConfirmed) {
            axios.delete('venueUser/'+data.vu_ID, {
              headers: {
                  Authorization: `Bearer ${token}`
              }
            }).then(res=>{
                getVenueUser(data);
            });
            Swal.fire({
              title: "Deletion Successful",
              text: "Features has been deleted.",
              icon: "success",
              color: "#fff",
              background: "#2b3035",
            });
          }
        })
      }  
  
      const create_venue_user = async (values) => {
        //e.preventDefault();
        try {
            await axios.post('venueUser', {
                pID: data.pID,
                id: values.id
            },  {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            Swal.fire({
              title: "Creation successful",
              text:"Venue User has been created successfully",
              icon: "success",
              color: "#fff",
              background: "#2b3035",
              timer: 3000,
              position: 'top-end'
            });
            getVenueUser(values);
        } catch (error) {
            if (error.response) {
                console.log(error.response.data.msg);
            }
        }
    }


    return (
        <Container>
            <Tabs
                  defaultActiveKey="venueUser"
                  id="justify-tab-example"
                  className="mb-3 text-white"
                  justify
                  >
                  <Tab eventKey="venueUser" title="Venue User">
                    <Row>
                      <Formik 
                        validationSchema={schemaVenueUser}
                        onSubmit={values => {
                          create_venue_user(values);
                        }}
                        initialValues={{
                            id: '',
                            pID: data.pID
                        }}>
                        {({ handleSubmit, handleChange, values, touched, errors }) => (
                        <Form noValidate onSubmit={handleSubmit}>  
                        <Form.Group as={Col} md="12" controlId="validationFormikName">
                          <InputGroup hasValidation className="mb-3">
                            <Button variant="outline-success" type="submit">
                              Add Venue
                            </Button>
                            
                              <Form.Select
                                  name="id"
                                  value={values.id}
                                  onChange={handleChange}
                                  isInvalid={!!errors.id}
                                  isValid={touched.id && !errors.id}
                              >
                              <option value="">Please Select</option>
                              {venue.map((option, i) => {
                                return (
                                  <option value={option.id} key={i}>
                                    {option.name + ' -' + option.domisili}
                                  </option>
                                ) ; 
                              })}
                              </Form.Select>
                              <Form.Control.Feedback type="invalid">
                                  {errors.id}
                              </Form.Control.Feedback>
                          </InputGroup>
                          </Form.Group>
                        </Form>
                        )}
                      </Formik>
                    </Row>
                    <Row>
                      <Table striped bordered hover variant="dark" responsive>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Venue Name</th>
                            <th>Venue Image</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                        {venueData.length > 0 ? (
                            venueData.map((data, i) => (
                              <tr key={i} style={{ background: '#fff' }}>
                                <td>{ i + 1}</td>
                                <td>{data.name + ' - ' + data.domisili}</td>
                                <td>
                                    <Figure.Image width={171}
                                            height={180}
                                            src={REACT_APP_IMAGE_URL + 'uploads/venue/' + data.main_image} alt={data.nama}>
                                    
                                    </Figure.Image>
                                </td>
                                <td>
                                 <ButtonGroup >
                                    <Button variant="outline-danger" className="me-2" onClick={deleteVenueUser(data)}>Delete</Button>
                                  </ButtonGroup>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="7">No Venues managed by this user</td>
                            </tr>
                          )}
                        </tbody>
                      </Table>       
                    </Row>
                  </Tab>
                  
          </Tabs>
        </Container>
    )

  
}

export default UserDetail