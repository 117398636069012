import React, {useState, useEffect} from "react"
import { Container, Row, Col, Button, Tabs, Tab, Form } from "react-bootstrap"
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

import InputGroup from 'react-bootstrap/InputGroup';
import * as formik from 'formik';
import * as yup from 'yup';
import Swal from "sweetalert2";


const VenueCreate = () => {
    const { Formik } = formik;

    const schema = yup.object().shape({
        name: yup.string().required('Venue name is required'),
        domisili: yup.string().required('Domisili is required'),
        permalink: yup.string().required('Permalink is required'),
        meta_key: yup.string().required('Meta keyword is required'),
        meta_desc: yup.string().required('Meta Description is required'),
        location: yup.string().required('Address is required'),
        opening_hour: yup.string().required('Opening Hour is required'),
        lat: yup.number().required('Latitude is required'),
        lng: yup.number().required('Longitude is required'),
        phone: yup.number().required().positive().integer('Phone Number is required'),
        website: yup.string().nullable(),
        youtube: yup.string().nullable(),
        description1: yup.string().required('Description 1 cannot be empty'),
        description2: yup.string().required('Description 2 cannot be empty'),
        type: yup.string().required('Venue needs to be categorized'),
        has_barandlounge: yup.number().required('Please chooose one of the option'),
        has_hotel: yup.number().required('Please chooose one of the option'),
        has_spa: yup.number().required('Please chooose one of the option'),
        has_karaoke: yup.number().required('Please chooose one of the option'),
        has_nightclub: yup.number().required('Please chooose one of the option'),
        has_lc: yup.number().required('Please chooose one of the option'),
        has_pay: yup.number().required('Merchant class is required'),
        city_ID: yup.number().required('City needs to be selected')
    });

    const [token, setToken] = useState('');
    const [cities, setCities] = useState([]);
    const [expire, setExpire] = useState('');
    const {REACT_APP_API_URL} = process.env;
    axios.defaults.baseURL = REACT_APP_API_URL;
    const history = useNavigate();

    const [image, setImage] = useState('');

    const refreshToken = async () => {
        try {
            const response = await axios.get('token');
            setToken(response.data.accessToken);
            const decoded = jwtDecode(response.data.accessToken);
            // console.log('DECODED: ', decoded);
            setExpire(decoded.exp);
        } catch (error) {
            if (error.response) {
                history("/");
            }
        }
    }

    useEffect(() => {
        refreshToken();
        getCity();
      }, []);

      const create_venue = async (values) => {
        //e.preventDefault();
        try {
            console.log('INPUT VALUE: ', values)
            console.log('IMAGE VALUE: ', image)
            await axios.post('venue', {
              name: values.name,
              permalink: values.permalink,
              meta_key: values.meta_key,
              meta_desc: values.meta_desc,
              city_ID: values.city_ID,
              domisili: values.domisili,
              location: values.location,
              opening_hour: values.opening_hour,
              lat: values.lat,
              lng: values.lng,
              phone: values.phone,
              website: values.website,
              description1: values.description1,
              description2: values.description2,
              has_nightclub: values.has_nightclub,
              has_barandlounge: values.has_barandlounge,
              has_hotel: values.has_hotel,
              has_spa: values.has_spa,
              has_karaoke: values.has_karaoke,
              has_lc: values.has_lc,
              has_pay: values.has_pay,
              type: values.type,
              youtube: values.youtube,
              file: image
            },  {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });
            Swal.fire({
                title: "Venue creation successful",
                text:"Gallery, Features and Events can be editted in Venue Details",
                icon: "success",
                color: "#fff",
                background: "#2b3035",
                timer: 3000,
                position: 'top-end'
              });
            history('/venues', {state: {name: values.name}});
        } catch (error) {
            if (error.response) {
                console.log(error.response.data.msg);
            }
        }
    }

    const axiosJWT = axios.create();
 
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get('token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwtDecode(response.data.accessToken);
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const getCity = async() => {
      axiosJWT
      .get("city" , {
        headers: {
            Authorization: `Bearer ${token}`
        }
      })
      .then((result) => {
        setCities([...result.data]);
      })
      .catch((error) => console.log(error));
    }   

    return (
        <Container>
            <Tabs
                defaultActiveKey="create"
                id="justify-tab-example"
                className="mb-3 text-white"
                >
                <Tab eventKey="create" title="Add Venues">
                        <Row>
                        <Formik
                            validationSchema={schema}
                            onSubmit={values => {
                            create_venue(values);
                            }}
                            initialValues={{
                                name: '',
                                permalink: '',
                                meta_key: '',
                                meta_desc: '',
                                city_ID: '',
                                domisili: '',
                                location: '',
                                opening_hour: '',
                                lat: '',
                                lng: '',
                                phone: '',
                                website: '',
                                description1: '',
                                description2: '',
                                has_nightclub: '',
                                has_barandlounge: '',
                                has_hotel: '',
                                has_spa: '',
                                has_karaoke: '',
                                has_lc: '',
                                has_pay: '',
                                type: '',
                                youtube: ''
                            }}
                            >
                            {({ handleSubmit, handleChange, values, touched, errors }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                <Row className="mb-2">
                                    <Form.Group as={Col} md="4" controlId="validationFormikName">
                                    <Form.Label className="text-white">Venue Name</Form.Label>
                                    <InputGroup hasValidation>
                                        <Form.Control
                                        type="text"
                                        placeholder="Venue Name..."
                                        aria-describedby="inputGroupPrepend"
                                        name="name"
                                        value={values.name}
                                        onChange={handleChange}
                                        isInvalid={!!errors.name}
                                        isValid={touched.name && !errors.name}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                        {errors.name}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationFormiDomisili">
                                    <Form.Label className="text-white">Venue Domisili</Form.Label>
                                    <InputGroup hasValidation>
                                        <Form.Control
                                        type="text"
                                        placeholder="Venue Domisili..."
                                        aria-describedby="inputGroupPrepend"
                                        name="domisili"
                                        value={values.domisili}
                                        onChange={handleChange}
                                        isInvalid={!!errors.domisili}
                                        isValid={touched.domisili && !errors.domisili}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                        {errors.domisili}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationFormikLocation">
                                    <Form.Label className="text-white">Venue Location</Form.Label>
                                    <InputGroup hasValidation>
                                        <Form.Control
                                        type="text"
                                        placeholder="Venue Location..."
                                        aria-describedby="inputGroupPrepend"
                                        name="location"
                                        value={values.location}
                                        onChange={handleChange}
                                        isInvalid={!!errors.location}
                                        isValid={touched.location && !errors.location}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                        {errors.location}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                    </Form.Group>
                                </Row>
                                <Row className="mb-2">
                                    <Form.Group as={Col} md="3" controlId="validationFormikWebsite">
                                    <Form.Label className="text-white">Venue Website</Form.Label>
                                    <InputGroup hasValidation>
                                        <Form.Control
                                        type="text"
                                        placeholder="Venue Website Url..."
                                        aria-describedby="inputGroupPrepend"
                                        name="website"
                                        value={values.website}
                                        onChange={handleChange}
                                        isInvalid={!!errors.website}
                                        isValid={touched.website && !errors.website}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                        {errors.website}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} md="3" controlId="validationFormikWebsite">
                                    <Form.Label className="text-white">Youtube Streaming ID</Form.Label>
                                    <InputGroup hasValidation>
                                        <Form.Control
                                        type="text"
                                        placeholder="Youtube Livestream ID..."
                                        aria-describedby="inputGroupPrepend"
                                        name="youtube"
                                        value={values.youtube}
                                        onChange={handleChange}
                                        isInvalid={!!errors.youtube}
                                        isValid={touched.youtube && !errors.youtube}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                        {errors.youtube}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} md="3" controlId="validationFormikWebsite">
                                    <Form.Label className="text-white">Permalink</Form.Label>
                                    <InputGroup hasValidation>
                                        <Form.Control
                                        type="text"
                                        placeholder="Venue url permalink..."
                                        aria-describedby="inputGroupPrepend"
                                        name="permalink"
                                        value={values.permalink}
                                        onChange={handleChange}
                                        isInvalid={!!errors.permalink}
                                        isValid={touched.permalink && !errors.permalink}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                        {errors.permalink}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} md="3" controlId="validationFormikLocation">
                                    <Form.Label className="text-white">Meta Keywords</Form.Label>
                                    <InputGroup hasValidation>
                                        <Form.Control
                                        type="text"
                                        placeholder="Meta Keywords..."
                                        aria-describedby="inputGroupPrepend"
                                        name="meta_key"
                                        value={values.meta_key}
                                        onChange={handleChange}
                                        isInvalid={!!errors.location}
                                        isValid={touched.meta_key && !errors.meta_key}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                        {errors.meta_key}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                    </Form.Group>
                                </Row>
                                <Row className="mb-2">
                                    <Form.Group as={Col} md="4" controlId="validationFormiDescription1">
                                    <Form.Label className="text-white">Meta Description</Form.Label>
                                    <InputGroup hasValidation>
                                        <Form.Control as="textarea" rows={3}
                                        type="text"
                                        placeholder="Meta Description..."
                                        aria-describedby="inputGroupPrepend"
                                        name="meta_desc"
                                        value={values.meta_desc}
                                        onChange={handleChange}
                                        isInvalid={!!errors.meta_desc}
                                        isValid={touched.meta_desc && !errors.meta_desc}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                        {errors.meta_desc}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationFormiDescription1">
                                    <Form.Label className="text-white">Venue Description 1</Form.Label>
                                    <InputGroup hasValidation>
                                        <Form.Control as="textarea" rows={3}
                                        type="text"
                                        placeholder="Venue Description 1..."
                                        aria-describedby="inputGroupPrepend"
                                        name="description1"
                                        value={values.description1}
                                        onChange={handleChange}
                                        isInvalid={!!errors.description1}
                                        isValid={touched.description1 && !errors.description1}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                        {errors.description1}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationFormikDescription2">
                                    <Form.Label className="text-white">Venue Description 2</Form.Label>
                                    <InputGroup hasValidation>
                                        <Form.Control
                                        type="text" as="textarea" rows={3}
                                        placeholder="Venue Description 2..."
                                        aria-describedby="inputGroupPrepend"
                                        name="description2"
                                        value={values.description2}
                                        onChange={handleChange}
                                        isInvalid={!!errors.description2}
                                        isValid={touched.description2 && !errors.description2}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                        {errors.description2}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                    </Form.Group>
                                </Row>
                                <Row className="mb-2">
                                <Form.Group as={Col} md="3" controlId="validationFormikPhone">
                                    <Form.Label className="text-white">Venue Phone Number</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Venue Phone Number"
                                        name="phone"
                                        value={values.phone}
                                        onChange={handleChange}
                                        isInvalid={!!errors.phone}
                                        isValid={touched.phone && !errors.phone}
                                    />

                                    <Form.Control.Feedback type="invalid">
                                        {errors.phone}
                                    </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="3" controlId="validationFormikOH">
                                    <Form.Label className="text-white">Opening Hour</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Venue Opening Hour"
                                        name="opening_hour"
                                        value={values.opening_hour}
                                        onChange={handleChange}
                                        isInvalid={!!errors.opening_hour}
                                        isValid={touched.opening_hour && !errors.opening_hour}
                                    />

                                    <Form.Control.Feedback type="invalid">
                                        {errors.opening_hour}
                                    </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="3" controlId="validationFormikLat">
                                    <Form.Label className="text-white">Latitude</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Venue Map Latitude"
                                        name="lat"
                                        value={values.lat}
                                        onChange={handleChange}
                                        isInvalid={!!errors.lat}
                                        isValid={touched.lat && !errors.lat}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.lat}
                                    </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="3" controlId="validationFormikLng">
                                    <Form.Label className="text-white">Longitude</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Venue Map Longitude"
                                        name="lng"
                                        value={values.lng}
                                        onChange={handleChange}
                                        isInvalid={!!errors.lng}
                                        isValid={touched.lng && !errors.lng}
                                    />

                                    <Form.Control.Feedback type="invalid">
                                        {errors.lng}
                                    </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row className="mb-2">
                                <Form.Group as={Col} md="3" className="mb-2" controlId="validationFormikBar">
                                    <Form.Label className="text-white">Bar & Lounge</Form.Label>
                                    <Form.Select
                                        name="has_barandlounge"
                                        value={values.has_barandlounge}
                                        onChange={handleChange}
                                        isInvalid={!!errors.has_barandlounge}
                                        isValid={touched.has_barandlounge && !errors.has_barandlounge}
                                    >
                                    <option value="">Please Select</option>
                                    <option value="0">No</option>
                                    <option value="1">Yes</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.has_barandlounge}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="3" className="mb-2" controlId="validationFormikHotel">
                                    <Form.Label className="text-white">Hotel</Form.Label>
                                    <Form.Select
                                        name="has_hotel"
                                        value={values.has_hotel}
                                        onChange={handleChange}
                                        isInvalid={!!errors.has_hotel}
                                        isValid={touched.has_hotel && !errors.has_hotel}
                                    >
                                    <option value="">Please Select</option>
                                    <option value="0">No</option>
                                    <option value="1">Yes</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.has_hotel}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="3" className="mb-2" controlId="validationFormikNightclub">
                                    <Form.Label className="text-white">Nightclub</Form.Label>
                                    <Form.Select
                                        name="has_nightclub"
                                        value={values.has_nightclub}
                                        onChange={handleChange}
                                        isInvalid={!!errors.has_nightclub}
                                        isValid={touched.has_nightclub && !errors.has_nightclub}
                                    >
                                    <option value="">Please Select</option>
                                    <option value="0">No</option>
                                    <option value="1">Yes</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.has_nightclub}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="3" className="mb-2" controlId="validationFormikSpa">
                                    <Form.Label className="text-white">Spa</Form.Label>
                                    <Form.Select
                                        name="has_spa"
                                        value={values.has_spa}
                                        onChange={handleChange}
                                        isInvalid={!!errors.has_spa}
                                        isValid={touched.has_spa && !errors.has_spa}
                                    >
                                    <option value="">Please Select</option>
                                    <option value="0">No</option>
                                    <option value="1">Yes</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.has_spa}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="3" className="mb-2" controlId="validationFormikKaraoke">
                                    <Form.Label className="text-white">Karaoke</Form.Label>
                                    <Form.Select
                                        name="has_karaoke"
                                        value={values.has_karaoke}
                                        onChange={handleChange}
                                        isInvalid={!!errors.has_karaoke}
                                        isValid={touched.has_karaoke && !errors.has_karaoke}
                                    >
                                    <option value="">Please Select</option>
                                    <option value="0">No</option>
                                    <option value="1">Yes</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.has_karaoke}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="3" className="mb-2" controlId="validationFormikLc">
                                    <Form.Label className="text-white">Companion</Form.Label>
                                    <Form.Select
                                        name="has_lc"
                                        value={values.has_lc}
                                        onChange={handleChange}
                                        isInvalid={!!errors.has_lc}
                                        isValid={touched.has_lc && !errors.has_lc}
                                    >
                                    <option value="">Please Select</option>
                                    <option value="0">No</option>
                                    <option value="1">Yes</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.has_lc}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="3" className="mb-2" controlId="validationFormikType">
                                    <Form.Label className="text-white">Type</Form.Label>
                                    <Form.Select
                                        name="type"
                                        value={values.type}
                                        onChange={handleChange}
                                        isInvalid={!!errors.type}
                                        isValid={touched.type && !errors.type}
                                    >
                                    <option value="">Please Select</option>
                                    <option value="disco">Nightclub</option>
                                    <option value="hotel">Hotel</option>
                                    <option value="karaoke">Karaoke</option>
                                    <option value="spa">Spa</option>
                                    <option value="bar">Bar & Lounge</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.type}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="3" className="mb-2" controlId="validationFormikPay">
                                    <Form.Label className="text-white">Merchant Class</Form.Label>
                                    <Form.Select
                                        name="has_pay"
                                        value={values.has_pay}
                                        onChange={handleChange}
                                        isInvalid={!!errors.has_pay}
                                        isValid={touched.has_pay && !errors.has_pay}
                                    >
                                    <option value="">Please Select</option>
                                    <option value="0">Normal</option>
                                    <option value="1">Bronze</option>
                                    <option value="2">Silver</option>
                                    <option value="3">Ruby</option>
                                    <option value="4">Gold</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.has_pay}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                </Row>
                                <Row>
                                <Form.Group as={Col} md="6" className="mb-2" controlId="validationFormikPay">
                                    <Form.Label className="text-white">City Name</Form.Label>
                                    <Form.Select
                                        name="city_ID"
                                        value={values.city_ID}
                                        onChange={handleChange}
                                        isInvalid={!!errors.city_ID}
                                        isValid={touched.city_ID && !errors.city_ID}
                                    >
                                    <option value="">Please Select</option>
                                    {cities.map((option, i) => {
                                    return (
                                        <option value={option.city_ID} key={i}>
                                        {option.city_name}
                                        </option>
                                    ) ; 
                                    })}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.city_ID}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formFile" className="mb-3">
                                    <Form.Label className="text-white">Image</Form.Label>
                                    <Form.Control type="file" name="file" onChange={(e) => setImage(e.target.files[0])}/>
                                </Form.Group>
                                </Row>
                                <Button type="submit">Create Venues</Button>
                                </Form>
                            )}
                            </Formik>
                        </Row>
                    </Tab>
            </Tabs> 
        </Container>
    )
}

export default VenueCreate