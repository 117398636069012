import React from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavDropdown } from 'react-bootstrap';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { NavLink } from 'react-router-dom';

const Header = () => {
    const expand = false;
    const history = useNavigate();
    const {REACT_APP_API_URL} = process.env
    axios.defaults.baseURL = REACT_APP_API_URL;

    const Logout = async () => {
      try {
          await axios.delete('logout');
          history("/");
      } catch (error) {
          console.log(error);
      }
  }
    return (
        <Navbar key={expand} expand={expand} className="bg-body-tertiary mb-5" bg='dark' data-bs-theme="dark">
          <Container>
            <Navbar.Brand href="#">Nightbutterfly CMS</Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
              style={{backgroundColor: "#4c565f"}}
              className="text-white"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  Night Butterfly
                </Offcanvas.Title>
              </Offcanvas.Header>
              <hr/>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <NavLink to="/dashboard" className="nav-link">Dashboard</NavLink>
                  <NavLink to="/admins" className="nav-link">Admins</NavLink>
                  <NavDropdown
                    title="Manage Venues"
                    id={`offcanvasNavbarDropdown-expand-${expand}`}
                  >
                    <NavDropdown.Item><NavLink to="/venues" className="nav-link">List Venues</NavLink></NavDropdown.Item>
                    <NavDropdown.Item><NavLink to="/venuesCreate" className="nav-link">Add Venue</NavLink></NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown
                    title="Manage Blogs"
                    id={`offcanvasNavbarDropdown-expand-${expand}`}
                  >
                    <NavDropdown.Item><NavLink to="/blogs" className="nav-link">List Blogs</NavLink></NavDropdown.Item>
                    {/* <NavDropdown.Item><NavLink to="/blogsCreate" className="nav-link">Add Blog</NavLink></NavDropdown.Item> */}
                  </NavDropdown>
                  <NavDropdown
                    title="Manage Cities"
                    id={`offcanvasNavbarDropdown-expand-${expand}`}
                  >
                    <NavDropdown.Item><NavLink to="/cities" className="nav-link">List Cities</NavLink></NavDropdown.Item>
                    {/* <NavDropdown.Item><NavLink to="/citiesCreate" className="nav-link">Add City</NavLink></NavDropdown.Item> */}
                  </NavDropdown>
                  <NavDropdown
                    title="Meta Setting"
                    id={`offcanvasNavbarDropdown-expand-${expand}`}
                  >
                    <NavDropdown.Item><NavLink to="/settings" className="nav-link">Page Meta Setting</NavLink></NavDropdown.Item>
                    {/* <NavDropdown.Item><NavLink to="/citiesCreate" className="nav-link">Add City</NavLink></NavDropdown.Item> */}
                  </NavDropdown>
                  <NavDropdown
                    title="Banners"
                    id={`offcanvasNavbarDropdown-expand-${expand}`}
                  >
                    <NavDropdown.Item><NavLink to="/banners" className="nav-link">Ads Banner</NavLink></NavDropdown.Item>
                    {/* <NavDropdown.Item><NavLink to="/citiesCreate" className="nav-link">Add City</NavLink></NavDropdown.Item> */}
                  </NavDropdown>
                  
                  <Nav.Link to="" onClick={Logout}>Logout</Nav.Link>
                  
                </Nav>
                </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
  )
}

export default Header