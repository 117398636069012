import React, {useState, useEffect} from "react"
import { Container, Row, Col, Button, Tabs, Tab, Table, Form, Modal, ButtonGroup } from "react-bootstrap"
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import CustomPagination from './CustomPagination';
import Swal from "sweetalert2";
import { Placeholder } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";

const Settings = () => {
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [apiData, setApiData] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const {REACT_APP_API_URL} = process.env
    const {REACT_APP_IMAGE_URL} = process.env
    axios.defaults.baseURL = REACT_APP_API_URL;
    const history = useNavigate();

    const [inputVals2, setInputVals2] = useState({
        sID: "",
        meta_title: "",
        meta_key: "",
        meta_desc: ""
        });   
    
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (data) => { 
      setInputVals2({...inputVals2, 
        sID: data.sID,
        meta_title: data.meta_title,
        meta_key: data.meta_key,
        meta_desc: data.meta_desc
      })
      setShow(true);
    }      
    const [msg, setMsg] = useState('');   

    const [searchFilter, setSearchFilter] = useState(''); // filter the search
    const [currentPage, setCurrentPage] = useState(1); // set the current page
    const pageSize = 20; // show row in table

    useEffect(() => {
        refreshToken();
        getApiData();
      }, [searchFilter]);

      const handleFilter = (e) => {
        setSearchFilter(e.target.value);
      };
    
      const filteredData = apiData.filter(
        (item) =>
          item.meta_title.toLowerCase().includes(searchFilter.toLowerCase()) 
      );
    
      const paginatedData = filteredData.slice(
        (currentPage - 1) * pageSize,
        currentPage * pageSize
      );

      const refreshToken = async () => {
        try {
            const response = await axios.get('token');
            setToken(response.data.accessToken);
            const decoded = jwtDecode(response.data.accessToken);
            // console.log('DECODED: ', decoded);
            setExpire(decoded.exp);
        } catch (error) {
            if (error.response) {
                history("/");
            }
        }
    }
 
    const axiosJWT = axios.create();
 
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get('token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwtDecode(response.data.accessToken);
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });
 
    const getApiData = async () => {
        const response = await axiosJWT.get('dbsettings', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        setApiData([...response.data]);
        setLoading(false);
        // console.log('USERS: ', response.data);
    }

    const updateData = (data) => (e) => {
      axios.put('settings/'+data, {
              meta_title: inputVals2.meta_title,
              meta_key: inputVals2.meta_key,
              meta_desc: inputVals2.meta_desc
          },  {
              headers: {
                  Authorization: `Bearer ${token}`
              }
          }).then(res=>{
            Swal.fire({
                title: "Update successful",
                text:"Meta Setting has been updated successfully",
                icon: "success",
                color: "#fff",
                background: "#2b3035",
                timer: 3000,
                position: 'top-end'
              });  
          setShow(false);  
          getApiData();
      });
      console.log("Value: ", inputVals2);
      console.log("Data: ", data);
    }
    
    const placeholder = [
      <tr>
        <td><Placeholder animation="glow">
              <Placeholder xs={12} />
            </Placeholder></td>
        <td><Placeholder animation="glow">
              <Placeholder xs={12} />
        </Placeholder></td>
        <td><Placeholder animation="glow">
              <Placeholder xs={12} />
            </Placeholder></td>
        <td><Placeholder animation="glow">
              <Placeholder xs={12} />
            </Placeholder></td>
        <td><Placeholder animation="glow">
            <Placeholder xs={12} />
        </Placeholder></td>
        
      </tr>
    ]

    const listPlaceholder = [];

  for (let i = 0; i < 10; i++)
  {
    listPlaceholder.push(placeholder);
  }

  if (isLoading) {
    return (
      <>
      <Container>
        <Tabs
                id="justify-tab-example"
                className="mb-3 text-white"
                >
                <Tab eventKey="venues" title="Venues">
                    <Row>
                      <Col>
                      <Table striped bordered hover variant="dark" responsive>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Meta Title</th>
                            <th>Meta Keywords</th>
                            <th>Meta Description</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {listPlaceholder}
                            <tr>
                              <td colSpan="4" className="text-center">
                                <Spinner animation="border" role="status" size="sm"/>
                                <Spinner animation="border" role="status" size="sm"/>  
                                <Spinner animation="border" role="status" size="sm" className="me-2"/>Loading Data...
                                </td>
                            </tr>
                        </tbody>
                      </Table>
                      </Col>
                    </Row>
                </Tab>
        </Tabs>
      </Container>
      </>
    )
  }  

  return (
    <>
      <Container>
        <Tabs
                defaultActiveKey="settings"
                id="justify-tab-example"
                className="mb-3 text-white"
                justify
                >
                <Tab eventKey="settings" title="Page Settings">
                    <Row>
                      <Col>
                      <input
                        style={{ width: "200px" }}
                        className='form-control mb-2'
                        placeholder='Search'
                        value={searchFilter}
                        onChange={handleFilter}
                      />
                      <Table striped bordered hover variant="dark" responsive>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Meta Title</th>
                            <th>Meta Keywords</th>
                            <th>Meta Description</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                        {paginatedData.length > 0 ? (
                            paginatedData.map((data, i) => (
                              <tr key={i} style={{ background: '#fff' }}>
                                <td>{(currentPage - 1) * pageSize + i + 1}</td>
                                <td>{data.meta_title}</td>
                                <td>{data.meta_key}</td>
                                <td>{data.meta_desc}</td>
                                <td>
                                  <ButtonGroup >
                                    <Button variant="outline-primary" onClick={() => handleShow(data)}>Update</Button>
                                  </ButtonGroup>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <>
                            <tr>
                               <td colSpan="5" className="text-center">
                                No Data Found...
                                </td>
                            </tr>
                            </>
                          )}
                        </tbody>
                      </Table>
                      </Col>
                    </Row>
                    <Row className="text-center">
                      <Col>
                      <div className='d-flex justify-content-md-center'>
                      {filteredData.length > 0 &&
                        <>
                          <CustomPagination
                            itemsCount={filteredData.length}
                            itemsPerPage={pageSize}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            alwaysShown={true}
                          />
                        </>
                      }
                      </div>
                      </Col>
                      </Row>
                </Tab>
        </Tabs>
      </Container>
      <Modal
        show={show}
        fullscreen={true}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header style={{backgroundColor: "#212529"}}>
          <Modal.Title className="text-white">Update Page Setting</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{backgroundColor: '#2c3034'}}>
            <Form className="box">
              <p className="has-text-centered">{msg}</p>
              <Form.Group className="mb-3" controlId="metatitle.ControlInput1">
                  <Form.Label className="text-white">Meta Title</Form.Label>
                  <Form.Control type="text" placeholder="input meta title..." 
                  value={inputVals2.meta_title} onChange={(e) => setInputVals2({...inputVals2, meta_title:e.target.value})}/>
              </Form.Group>
              <Form.Group className="mb-3" controlId="metatitle.ControlInput1">
                  <Form.Label className="text-white">Meta Title</Form.Label>
                  <Form.Control type="text" placeholder="input meta keywords..." 
                  value={inputVals2.meta_key} onChange={(e) => setInputVals2({...inputVals2, meta_key:e.target.value})}/>
              </Form.Group>
              <Form.Group className="mb-3" controlId="metatitle.ControlInput1">
                  <Form.Label className="text-white">Meta Description</Form.Label>
                  <Form.Control type="text" placeholder="input meta description..." 
                  value={inputVals2.meta_desc} onChange={(e) => setInputVals2({...inputVals2, meta_desc:e.target.value})}/>
              </Form.Group>
            </Form>
        </Modal.Body>
        <Modal.Footer style={{backgroundColor: "#212529"}}>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={updateData(inputVals2.sID)}>Update</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Settings