import React, {useState, useEffect} from "react"
import { Container, Row, Col, Button, Figure, Tabs, Tab, Table, Form, Modal, ButtonGroup } from "react-bootstrap"
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import CustomPagination from './CustomPagination';
import Swal from "sweetalert2";
import { Placeholder } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import parse from 'html-react-parser';

const Blogs = () => {
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [apiData, setApiData] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const {REACT_APP_API_URL} = process.env
    const {REACT_APP_IMAGE_URL} = process.env
    axios.defaults.baseURL = REACT_APP_API_URL;
    const history = useNavigate();

    

    const [image, setImage] = useState('');
    const [inputVals, setInputVals] = useState({
        blog_title: "",
        blog_desc: "",
        blog_permalink: "",
        blog_meta_key: "",
        blog_meta_desc: "",
        blog_date: ""
       });
    
    const [image2, setImage2] = useState('');
    const [inputVals2, setInputVals2] = useState({
        blog_ID : "",
        blog_title: "",
        blog_desc: "",
        blog_meta_key: "",
        blog_meta_desc: "",
        blog_date: ""
      });   

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (data) => { 
      setInputVals2({...inputVals2, 
        blog_ID: data.blog_ID,
        blog_title: data.blog_title,
        blog_desc: data.blog_desc,
        blog_permalink: data.blog_permalink,
        blog_meta_key: data.blog_meta_key,
        blog_meta_desc: data.blog_meta_desc,
        blog_date: data.blog_date
      })
      setShow(true);
    }  

    const [msg, setMsg] = useState('');      


    const [searchFilter, setSearchFilter] = useState(''); // filter the search
    const [currentPage, setCurrentPage] = useState(1); // set the current page
    const pageSize = 20; // show row in table

    useEffect(() => {
        refreshToken();
        getApiData();
      }, [searchFilter]);

      const create_blog = async (e) => {
        e.preventDefault();
        try {
            console.log('INPUT VALUE: ', inputVals)
            console.log('IMAGE VALUE: ', image)
            await axios.post('blogs', {
                blog_title: inputVals.blog_title,
                blog_permalink: inputVals.blog_permalink,
                blog_meta_key: inputVals.blog_meta_key,
                blog_meta_desc: inputVals.blog_meta_desc,
                blog_desc: inputVals.blog_desc,
                blog_date: inputVals.blog_date,
                file: image
            },  {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });
            Swal.fire({
              title: "Creation successful",
              text:"New Blog has been created successfully",
              icon: "success",
              color: "#fff",
              background: "#2b3035",
              timer: 3000,
              position: 'top-end'
            });
            getApiData();
        } catch (error) {
            if (error.response) {
                setMsg(error.response.data.msg);
            }
        }
    }

      const handleFilter = (e) => {
        setSearchFilter(e.target.value);
      };
    
      const filteredData = apiData.filter(
        (item) =>
          item.blog_title.toLowerCase().includes(searchFilter.toLowerCase()) 
      );
    
      const paginatedData = filteredData.slice(
        (currentPage - 1) * pageSize,
        currentPage * pageSize
      );

      const refreshToken = async () => {
        try {
            const response = await axios.get('token');
            setToken(response.data.accessToken);
            const decoded = jwtDecode(response.data.accessToken);
            // console.log('DECODED: ', decoded);
            setExpire(decoded.exp);
        } catch (error) {
            if (error.response) {
                history("/");
            }
        }
    }
 
    const axiosJWT = axios.create();
 
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get('token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwtDecode(response.data.accessToken);
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });
 
    const getApiData = async () => {
        const response = await axiosJWT.get('blogs', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        setApiData([...response.data]);
        setLoading(false);
        // console.log('USERS: ', response.data);
    }

    const deleteData = (data) => (e) => {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this action!",
          icon: "warning",
          color: "#fff",
          background: "#2b3035",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Delete!"
        }).then((result) => {
          if (result.isConfirmed) {
            axios.delete('blogs/'+data.blog_ID, { data: { filename: data.blog_img_link } }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(res=>{
                getApiData();
            });
            Swal.fire({
              title: "Deletion Successful",
              text: "Blog has been deleted.",
              icon: "success",
              color: "#fff",
              background: "#2b3035",
            });
          }
        })
        
      }
    
      const updateData = (data) => (e) => {
        axios.put('blogs/'+data, {
                blog_title: inputVals2.blog_title,
                blog_permalink: inputVals2.blog_permalink,
                blog_meta_key: inputVals2.blog_meta_key,
                blog_meta_desc: inputVals2.blog_meta_desc,
                blog_desc: inputVals2.blog_desc,
                blog_date: inputVals2.blog_date,
                file: image2
            },  {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            }).then(res=>{
              Swal.fire({
                title: "Update successful",
                text:"Blog has been updated successfully",
                icon: "success",
                color: "#fff",
                background: "#2b3035",
                timer: 3000,
                position: 'top-end'
              });
            setShow(false)  
            getApiData();
        });
        // console.log("Value: ", inputVals2);
        // console.log("Data: ", data);
        // console.log("Image:", image2);
      }  

      const placeholder = [
        <tr>
          <td><Placeholder animation="glow">
                <Placeholder xs={12} />
              </Placeholder></td>
          <td><Placeholder animation="glow">
                <Placeholder xs={12} />
          </Placeholder></td>
          <td><Placeholder animation="glow">
                <Placeholder xs={12} />
              </Placeholder></td>
          <td><Placeholder animation="glow">
                <Placeholder xs={12} />
              </Placeholder></td>
          <td><Placeholder animation="glow">
                <Placeholder xs={12} />
              </Placeholder></td>
          <td><Placeholder animation="glow">
                <Placeholder xs={12} />
              </Placeholder></td>
          <td><Placeholder animation="glow">
                <Placeholder xs={12} />
              </Placeholder></td>
        </tr>
      ]

      const listPlaceholder = [];

    for (let i = 0; i < 10; i++)
    {
      listPlaceholder.push(placeholder);
    }

    const handleEditor = (event, editor) => {
      // console.log(editor.getData());
      setInputVals({...inputVals, blog_desc:editor.getData()})

    };

    const handleEditorUpdate = (event, editor) => {
      // console.log(editor.getData());
      setInputVals2({...inputVals2, blog_desc:editor.getData()})
    };

    if (isLoading) {
      return (
        <>
        <Container>
          <Tabs
                  id="justify-tab-example"
                  className="mb-3 text-white"
                  >
                  <Tab eventKey="venues" title="Venues">
                      <Row>
                        <Col>
                        <Table striped bordered hover variant="dark" responsive>
                          <thead>
                          <tr>
                            <th>#</th>
                            <th>Title</th>
                            <th>Description</th>
                            <th>Image</th>
                            <th>Date</th>
                            <th>Created</th>
                            <th>Action</th>
                          </tr>
                          </thead>
                          <tbody>
                            {listPlaceholder}
                              <tr>
                                <td colSpan="7" className="text-center">
                                  <Spinner animation="border" role="status" size="sm"/>
                                  <Spinner animation="border" role="status" size="sm"/>  
                                  <Spinner animation="border" role="status" size="sm" className="me-2"/>Loading Data...
                                  </td>
                              </tr>
                          </tbody>
                        </Table>
                        </Col>
                      </Row>
                  </Tab>
          </Tabs>
        </Container>
        </>
      )
    }  

  return (
    <>
      <Container>
        <Tabs
                defaultActiveKey="blogs"
                id="justify-tab-example"
                className="mb-3 text-white"
                justify
                >
                <Tab eventKey="blogs" title="Blogs">
                    <Row>
                      <Col>
                      <input
                        style={{ width: "200px" }}
                        className='form-control mb-2'
                        placeholder='Search'
                        value={searchFilter}
                        onChange={handleFilter}
                      />
                      <Table striped bordered hover variant="dark" responsive>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Title</th>
                            <th>Description</th>
                            <th>Meta Key</th>
                            <th>Meta Description</th>
                            <th>Image</th>
                            <th>Date</th>
                            <th>Created</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                        {paginatedData.length > 0 ? (
                            paginatedData.map((data, i) => (
                              <tr key={i} style={{ background: '#fff' }}>
                                <td>{(currentPage - 1) * pageSize + i + 1}</td>
                                <td>{data.blog_title}</td>
                                <td>{data.blog_desc.substring(0, 200)} </td>
                                <td>{data.blog_meta_key} </td>
                                <td>{data.blog_meta_desc} </td>
                                <td>
                                    <Figure.Image width={171}
                                            height={180}
                                            src={REACT_APP_IMAGE_URL + 'uploads/blog/' + data.blog_img_link} alt={data.blog_title}>
                                    
                                    </Figure.Image>
                                </td>
                                <td>{data.blog_date}</td>
                                <td>{data.blog_created_at}</td>
                                <td>
                                  <ButtonGroup >
                                    <Button variant="outline-danger" className="me-2" onClick={deleteData(data)}>Delete</Button>
                                    <Button variant="outline-primary" onClick={() => handleShow(data)}>View Detail</Button>
                                  </ButtonGroup>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <>
                            <tr>
                               <td colSpan="7" className="text-center">
                                Data Not Found...
                                </td>
                            </tr>
                            </>
                          )}
                        </tbody>
                      </Table>
                      </Col>
                    </Row>
                    <Row className="text-center">
                      <Col>
                      <div className='d-flex justify-content-md-center'>
                      {filteredData.length > 0 &&
                        <>
                          <CustomPagination
                            itemsCount={filteredData.length}
                            itemsPerPage={pageSize}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            alwaysShown={true}
                          />
                        </>
                      }
                      </div>
                      </Col>
                      </Row>
                </Tab>
                <Tab eventKey="create" title="Add Blogs">
                    <Row>
                        <Form onSubmit={create_blog} className="box" encType="multipart/form-data">
                            <p className="has-text-centered">{msg}</p>
                            <Form.Group className="mb-3" controlId="Title.ControlInput1">
                                <Form.Label className="text-white">Title</Form.Label>
                                <Form.Control type="text" placeholder="input blog title..." 
                                value={inputVals.blog_title} onChange={(e) => setInputVals({...inputVals, blog_title:e.target.value})}/>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="Title.ControlInput1">
                                <Form.Label className="text-white">Permalink</Form.Label>
                                <Form.Control type="text" placeholder="input blog permalink..." 
                                value={inputVals.blog_permalink} onChange={(e) => setInputVals({...inputVals, blog_permalink:e.target.value})}/>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="Description.ControlInput1">
                                <Form.Label className="text-white">Description</Form.Label>
                                {/* <Form.Control as="textarea" rows={4} placeholder="input blog description..." 
                                value={inputVals.blog_desc} onChange={(e) => setInputVals({...inputVals, blog_desc:e.target.value})}/> */}
                                <CKEditor
                                    editor={ ClassicEditor }
                                    data=''
                                    onChange={handleEditor}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="Title.ControlInput1">
                                <Form.Label className="text-white">Meta Key</Form.Label>
                                <Form.Control type="text" placeholder="input meta key..." 
                                value={inputVals.blog_meta_key} onChange={(e) => setInputVals({...inputVals, blog_meta_key:e.target.value})}/>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="Description.ControlInput1">
                                <Form.Label className="text-white">Meta Description</Form.Label>
                                <Form.Control as="textarea" rows={4} placeholder="input meta description..." 
                                value={inputVals.blog_meta_desc} onChange={(e) => setInputVals({...inputVals, blog_meta_desc:e.target.value})}/>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="Date.ControlInput1">
                                <Form.Label className="text-white">Date</Form.Label>
                                <Form.Control type="text" placeholder="input blog date..." 
                                value={inputVals.blog_date} onChange={(e) => setInputVals({...inputVals, blog_date:e.target.value})}/>
                            </Form.Group>
                            <Form.Group controlId="formFile" className="mb-3">
                                <Form.Label className="text-white">Image</Form.Label>
                                <Form.Control type="file" name="file" onChange={(e) => setImage(e.target.files[0])}/>
                            </Form.Group>
                            
                            <Button variant="outline-primary" type="submit">Create</Button>
                            
                        </Form>
                     </Row>
                </Tab>
        </Tabs>
      </Container>
      <Modal
        show={show}
        fullscreen={true}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header style={{backgroundColor: "#212529"}}>
          <Modal.Title className="text-white">Update Blog</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{backgroundColor: '#2c3034'}}>
            <Form className="box" encType="multipart/form-data">
              <p className="has-text-centered">{msg}</p>
              <Form.Group className="mb-3" controlId="Title.ControlInput1">
                  <Form.Label className="text-white">Title</Form.Label>
                  <Form.Control type="text" placeholder="input blog title..." 
                  value={inputVals2.blog_title} onChange={(e) => setInputVals2({...inputVals2, blog_title:e.target.value})}/>
              </Form.Group>
              <Form.Group className="mb-3" controlId="Title.ControlInput1">
                                <Form.Label className="text-white">Permalink</Form.Label>
                                <Form.Control type="text" placeholder="input blog permalink..." 
                                value={inputVals2.blog_permalink} onChange={(e) => setInputVals2({...inputVals2, blog_permalink:e.target.value})}/>
                            </Form.Group>
              <Form.Group className="mb-3" controlId="Description.ControlInput1">
                  <Form.Label className="text-white">Description</Form.Label>
                  {/* <Form.Control as="textarea" rows={4} placeholder="input blog description..." 
                  value={inputVals2.blog_desc} onChange={(e) => setInputVals2({...inputVals2, blog_desc:e.target.value})}/> */}
                  <CKEditor
                                    editor={ ClassicEditor }
                                    data={inputVals2.blog_desc}
                                    onChange={handleEditorUpdate}
                                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="Title.ControlInput1">
                                <Form.Label className="text-white">Meta Key</Form.Label>
                                <Form.Control type="text" placeholder="input meta key..." 
                                value={inputVals2.blog_meta_key} onChange={(e) => setInputVals2({...inputVals2, blog_meta_key:e.target.value})}/>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="Description.ControlInput1">
                                <Form.Label className="text-white">Meta Description</Form.Label>
                                <Form.Control as="textarea" rows={4} placeholder="input meta description..." 
                                value={inputVals2.blog_meta_desc} onChange={(e) => setInputVals2({...inputVals2, blog_meta_desc:e.target.value})}/>
                            </Form.Group>
              <Form.Group className="mb-3" controlId="Date.ControlInput1">
                  <Form.Label className="text-white">Date</Form.Label>
                  <Form.Control type="text" placeholder="input blog date..." 
                  value={inputVals2.blog_date} onChange={(e) => setInputVals2({...inputVals2, blog_date:e.target.value})}/>
              </Form.Group>
              <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label className="text-white">Image</Form.Label>
                  <Form.Control type="file" name="file" onChange={(e) => setImage2(e.target.files[0])}/>
              </Form.Group>
            </Form>
        </Modal.Body>
        <Modal.Footer style={{backgroundColor: "#212529"}}>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={updateData(inputVals2.blog_ID)}>Update</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Blogs