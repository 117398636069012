import React, {useState, useEffect} from "react"
import { Container, Row, Col, Button, Figure, Tabs, Tab, Table, Form, Modal, ButtonGroup } from "react-bootstrap"
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import CustomPagination from './CustomPagination';
import Swal from "sweetalert2";
import { Placeholder } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";

const Banners = () => {
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [apiData, setApiData] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const {REACT_APP_API_URL} = process.env
    const {REACT_APP_IMAGE_URL} = process.env
    axios.defaults.baseURL = REACT_APP_API_URL;
    const history = useNavigate();

    const [image, setImage] = useState('');
    const [inputVals, setInputVals] = useState({
        banner_url: "",
        banner_pos: "web"
       });

    
    const [msg, setMsg] = useState('');   

    const [searchFilter, setSearchFilter] = useState(''); // filter the search
    const [currentPage, setCurrentPage] = useState(1); // set the current page
    const pageSize = 20; // show row in table

    useEffect(() => {
        refreshToken();
        getApiData();
      }, [searchFilter]);

      const create_banner = async (e) => {
        e.preventDefault();
        try {
            console.log('INPUT VALUE: ', inputVals)
            console.log('IMAGE VALUE: ', image)
            await axios.post('banners', {
                banner_url: inputVals.banner_url,
                banner_pos: inputVals.banner_pos,
                file: image
            },  {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });
            Swal.fire({
              title: "Creation successful",
              text:"New Banner Advertisement has been created successfully",
              icon: "success",
              color: "#fff",
              background: "#2b3035",
              timer: 3000,
              position: 'top-end'
            });
            getApiData();
        } catch (error) {
            if (error.response) {
                setMsg(error.response.data.msg);
            }
        }
    }

      const handleFilter = (e) => {
        setSearchFilter(e.target.value);
      };
    
      const filteredData = apiData.filter(
        (item) =>
          item.banner_pos.toLowerCase().includes(searchFilter.toLowerCase()) 
      );
    
      const paginatedData = filteredData.slice(
        (currentPage - 1) * pageSize,
        currentPage * pageSize
      );

      const refreshToken = async () => {
        try {
            const response = await axios.get('token');
            setToken(response.data.accessToken);
            const decoded = jwtDecode(response.data.accessToken);
            // console.log('DECODED: ', decoded);
            setExpire(decoded.exp);
        } catch (error) {
            if (error.response) {
                history("/");
            }
        }
    }
 
    const axiosJWT = axios.create();
 
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get('token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwtDecode(response.data.accessToken);
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });
 
    const getApiData = async () => {
        const response = await axiosJWT.get('banners', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        setApiData([...response.data]);
        setLoading(false);
        // console.log('USERS: ', response.data);
    }

    const deleteData = (data) => (e) => {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this action!",
          icon: "warning",
          color: "#fff",
          background: "#2b3035",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Delete!"
        }).then((result) => {
          if (result.isConfirmed) {
            axios.delete('banners/'+data.banner_ID, { data: { filename: data.banner_img_link } }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(res=>{
                getApiData();
            });
            Swal.fire({
              title: "Deletion Successful",
              text: "Advertisement Banner has been deleted.",
              icon: "success",
              color: "#fff",
              background: "#2b3035",
            });
          }
        })
        
      }

    
    
    const placeholder = [
      <tr>
        <td><Placeholder animation="glow">
              <Placeholder xs={12} />
            </Placeholder></td>
        <td><Placeholder animation="glow">
              <Placeholder xs={12} />
        </Placeholder></td>
        <td><Placeholder animation="glow">
              <Placeholder xs={12} />
            </Placeholder></td>
        <td><Placeholder animation="glow">
              <Placeholder xs={12} />
            </Placeholder></td>
        <td><Placeholder animation="glow">
            <Placeholder xs={12} />
        </Placeholder></td>
        
      </tr>
    ]

    const listPlaceholder = [];

  for (let i = 0; i < 10; i++)
  {
    listPlaceholder.push(placeholder);
  }

  if (isLoading) {
    return (
      <>
      <Container>
        <Tabs
                id="justify-tab-example"
                className="mb-3 text-white"
                >
                <Tab eventKey="venues" title="Venues">
                    <Row>
                      <Col>
                      <Table striped bordered hover variant="dark" responsive>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Image</th>
                            <th>Banner Url</th>
                            <th>Position</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {listPlaceholder}
                            <tr>
                              <td colSpan="4" className="text-center">
                                <Spinner animation="border" role="status" size="sm"/>
                                <Spinner animation="border" role="status" size="sm"/>  
                                <Spinner animation="border" role="status" size="sm" className="me-2"/>Loading Data...
                                </td>
                            </tr>
                        </tbody>
                      </Table>
                      </Col>
                    </Row>
                </Tab>
        </Tabs>
      </Container>
      </>
    )
  }  

  return (
    <>
      <Container>
        <Tabs
                defaultActiveKey="banners"
                id="justify-tab-example"
                className="mb-3 text-white"
                justify
                >
                <Tab eventKey="banners" title="Advertisement Banners">
                    <Row>
                      <Col>
                      <input
                        style={{ width: "200px" }}
                        className='form-control mb-2'
                        placeholder='Search'
                        value={searchFilter}
                        onChange={handleFilter}
                      />
                      <Table striped bordered hover variant="dark" responsive>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Image</th>
                            <th>Banner Url</th>
                            <th>Position</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                        {paginatedData.length > 0 ? (
                            paginatedData.map((data, i) => (
                              <tr key={i} style={{ background: '#fff' }}>
                                <td>{(currentPage - 1) * pageSize + i + 1}</td>
                                <td>
                                    <Figure.Image width={171}
                                            height={180}
                                            src={REACT_APP_IMAGE_URL + 'uploads/banners/' + data.banner_img_link} alt={data.banner_img_link}>
                                    
                                    </Figure.Image>
                                </td>
                                <td><a href={data.banner_url} target='_blank' rel="noopener noreferrer">{data.banner_url}</a></td>
                                <td>{data.banner_pos}</td>
                                <td>
                                  <ButtonGroup >
                                    <Button variant="outline-danger" className="me-2" onClick={deleteData(data)}>Delete</Button>
                                  </ButtonGroup>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <>
                            <tr>
                               <td colSpan="5" className="text-center">
                                No Data Found...
                                </td>
                            </tr>
                            </>
                          )}
                        </tbody>
                      </Table>
                      </Col>
                    </Row>
                    <Row className="text-center">
                      <Col>
                      <div className='d-flex justify-content-md-center'>
                      {filteredData.length > 0 &&
                        <>
                          <CustomPagination
                            itemsCount={filteredData.length}
                            itemsPerPage={pageSize}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            alwaysShown={true}
                          />
                        </>
                      }
                      </div>
                      </Col>
                      </Row>
                </Tab>
                <Tab eventKey="create" title="Add Banners">
                    <Row>
                        <Form onSubmit={create_banner} className="box" encType="multipart/form-data">
                            <p className="has-text-centered">{msg}</p>
                            <Form.Group className="mb-3" controlId="cityname.ControlInput1">
                                <Form.Label className="text-white">Banner Url</Form.Label>
                                <Form.Control type="text" placeholder="input advertisement banner url link..." 
                                value={inputVals.banner_url} onChange={(e) => setInputVals({...inputVals, banner_url:e.target.value})}/>
                            </Form.Group>
                            <Form.Group as={Col} md="3" className="mb-2" controlId="validationFormikBar">
                                    <Form.Label className="text-white">Banner Position</Form.Label>
                                    <Form.Select
                                        name="position"
                                        value={inputVals.banner_pos}
                                        onChange={(e) => setInputVals({...inputVals, banner_pos:e.currentTarget.value})}
                                    >
                                    <option value="web">WEBSITE BANNER</option>
                                    <option value="web_discover">WEBSITE JELAJAHI BANNER</option>  
                                    <option value="discover_top">DISCOVER TOP</option>
                                    <option value="discover_bottom">DISCOVER BOTTOM</option>
                                    <option value="video_top">VIDEO TOP</option>
                                    <option value="video_bottom">VIDEO BOTTOM</option>
                                    <option value="map">MAP BANNER</option>
                                    </Form.Select>
                            </Form.Group>
                            <Form.Group controlId="formFile" className="mb-3">
                                <Form.Label className="text-white">Image</Form.Label>
                                <Form.Control type="file" name="file" onChange={(e) => setImage(e.target.files[0])}/>
                            </Form.Group>
                            
                            <Button variant="outline-primary" type="submit">Create</Button>
                            
                        </Form>
                     </Row>
                </Tab>
        </Tabs>
      </Container>
      
    </>
  )
}

export default Banners