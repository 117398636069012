import { Routes, Route } from "react-router-dom";
import Login from './components/Login';
import Dashboard from "./components/Dashboard";
import Header from "./components/Header";
import { Container } from "react-bootstrap";
import Blogs from "./components/Blogs";
import Venues from "./components/Venues";
import Admins from "./components/Admins";
import Cities from "./components/Cities";
import VenueCreate from "./components/VenueCreate";
import VenueDetail from "./components/VenueDetail";
import Settings from "./components/Settings";
import Banners from "./components/Banners";
import UserDetail from "./components/UserDetail";

function App() {


  return (
      <Routes>
      <Route path="/" element={<Login/>} />
        <Route path="/dashboard" element={
          <>
            <Header/>
            <Dashboard/>
          </>
        }/> 
        <Route path="/admins" element={
          <>
            <Header/>
            <Admins/>
          </>
        }/> 
        <Route path="/blogs" element={
          <>
            <Header/>
            <Blogs/>
          </>
        }/> 
        <Route path="/venues" element={
          <>
            <Header/>
            <Venues/>
          </>
        }/> 
        <Route path="/venuesCreate" element={
          <>
            <Header/>
            <VenueCreate/>
          </>
        }/> 
        <Route path="/venuesDetail" element={
          <>
            <Header/>
            <VenueDetail/>
          </>
        }/> 
        <Route path="/Cities" element={
          <>
            <Header/>
            <Cities/>
          </>
        }/> 
        <Route path="/settings" element={
          <>
            <Header/>
            <Settings/>
          </>
        }/> 
        <Route path="/banners" element={
          <>
            <Header/>
            <Banners/>
          </>
        }/> 
        <Route path="/usersDetail" element={
          <>
            <Header/>
            <UserDetail/>
          </>
        }/> 
      </Routes>
  );
}

export default App;
