import React from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import axios from 'axios'

const Login = () => {
    const [username, setuUsername] = useState('');
    const [password, setPassword] = useState('');
    const [msg, setMsg] = useState('');
    const history = useNavigate();
    const {REACT_APP_API_URL} = process.env
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            console.log(REACT_APP_API_URL);
            await axios.post('login', {
                pusername: username,
                ppassword: password
            }).then(res => {
                console.log(res.data);
            });
            history('/dashboard');
        } catch (error) {
            if (error.response) {
                setMsg(error.response.data.msg);
            }
        }
    }

  return (
    <div className="Auth-form-container">
      <form className="Auth-form">
        <div className="Auth-form-content">
          <h3 className="Auth-form-title">Login </h3>
          <h3 className="Auth-form-title">{msg} </h3>
          <div className="form-group mt-3">
            <label>Username</label>
            <input
              type="username"
              className="form-control mt-1"
              placeholder="Enter username"
              value={username} onChange={(e) => setuUsername(e.target.value)} 
            />
          </div>
          <div className="form-group mt-3">
            <label>Password</label>
            <input
              type="password"
              className="form-control mt-1"
              placeholder="Enter password"
              value={password} onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="d-grid gap-2 mt-3">
            <button type="submit" className="btn btn-dark" onClick={handleLogin}> 
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default Login